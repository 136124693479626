import React from 'react';
import pathfinder from '@fiverr-private/pathfinder';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import Link from '../../Links/Link';
import { LINKS } from '../constants';
import { bigQueryClickEvent, mixpanelInteractionEvent } from '../utils';

const TutorialsLink = () => {
    const { userId } = getContext();

    return (
        <Link
            href={pathfinder('logo_maker_tutorials_page')}
            className="fiverr-link nav-link themed-link"
            bigQueryData={bigQueryClickEvent(userId, LINKS.TUTORIALS)}
            mpData={mixpanelInteractionEvent(LINKS.TUTORIALS)}
        >
            <I18n k="header.logo_maker.tutorials" />
        </Link>
    );
};

export default TutorialsLink;
