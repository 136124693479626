import { MIXPANEL_IDEAS_CLICK_TYPE } from '../constants';

export const bigQueryClickEvent = (id, { bigQuery }) => ({
    group: 'header_clicks',
    type: `clicked_header.${bigQuery}`,
    user: { id, navigation_mode: 'logo_maker' },
});

export const mixpanelInteractionEvent = ({ mixpanel }) => ({
    eventName: 'Logo Maker - Header Menu - Interaction',
    eventData: { Item: mixpanel },
});

export const mixpanelIdeasClickEvent = (type, { mixpanel }) => ({
    eventName: 'Logo Maker - Header Menu - Ideas Click',
    eventData: { Type: MIXPANEL_IDEAS_CLICK_TYPE[type], Item: mixpanel },
});
