import { createContext } from 'react';
import { noop } from 'lodash';
import { INTEGRATIONS } from '../../constants';

const LogoMakerContext = createContext({
    isTransparent: false,
    integration: INTEGRATIONS.NONE,
    showBackButton: false,
    setIsTransparent: noop,
});

export default LogoMakerContext;
