import React from 'react';
import { string } from 'prop-types';
import { DARK_COLOR } from '../../constants';

const ElementorLogoMobile = ({ primaryColor = DARK_COLOR }) => (
    <svg width="243" height="38" viewBox="0 0 243 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M60.8137 12.7648H59.3538C57.87 12.7648 56.8717 13.2228 56.1434 14.8141V12.7648H50.6962C49.2123 12.7648 48.214 13.2228 47.4857 14.8141V12.7648H43.0335V26.2501H47.4831V19.4261C47.4831 17.5381 48.292 16.4055 49.8032 16.4055H51.6919V26.2501H56.1461V19.4261C56.1461 17.5381 56.9557 16.4055 58.4662 16.4055H60.8137V12.7648Z"
            fill={primaryColor}
        />
        <path
            d="M20.7478 26.2501H24.6598L29.5435 12.7648H25.0647L22.6905 20.6128L20.2623 12.7648H15.8101L20.7478 26.2501Z"
            fill={primaryColor}
        />
        <path
            d="M2.50887 26.2501H6.93573V16.4055H11.1452V26.2501H15.5387V12.7648H6.93573V11.9281C6.93573 11.0115 7.58129 10.4421 8.60698 10.4421H11.1412V6.80414H7.87339C4.66828 6.80414 2.50887 8.77547 2.50887 11.6588V12.7648H0V16.4055H2.50887V26.2501Z"
            fill={primaryColor}
        />
        <path
            d="M42.1166 19.2375C42.1166 16.4055 40.414 12.5755 35.2902 12.5755C30.4605 12.5755 28.5185 16.3515 28.5185 19.5088C28.5185 22.7181 30.7306 26.4675 35.5603 26.4675C38.4199 26.4675 40.8214 25.0375 41.7924 22.6915L37.8538 21.5855C37.5837 22.4521 36.7474 22.9341 35.5603 22.9341C33.9684 22.9341 33.0261 22.0675 32.7813 20.5608H42.0625C42.0892 20.2628 42.1166 19.8581 42.1166 19.2375ZM32.78 17.9428C33.0468 16.3248 33.8857 15.7041 35.2889 15.7041C36.8001 15.7041 37.6363 16.7835 37.7444 17.9428H32.78Z"
            fill={primaryColor}
        />
        <path d="M65.0225 6.64214H67.6047V26.2135H65.0225V6.64214Z" fill={primaryColor} />
        <path
            d="M68.8124 19.5088C68.8124 15.4908 71.7141 12.5901 75.7342 12.5901C79.7542 12.5901 82.6285 15.4888 82.6285 19.5088C82.6285 23.5288 79.7536 26.4281 75.7335 26.4281C71.7134 26.4281 68.8124 23.5255 68.8124 19.5088ZM79.9936 19.5088C79.9936 16.7681 78.2637 14.8521 75.7342 14.8521C73.1786 14.8521 71.448 16.7681 71.448 19.5088C71.448 22.2495 73.182 24.1661 75.7342 24.1661C78.2637 24.1641 79.9936 22.2481 79.9936 19.5088Z"
            fill={primaryColor}
        />
        <path
            d="M95.7291 27.4375C95.7291 29.8595 94.2119 31.6421 91.5763 31.6421H87.4229C84.6546 31.6421 83.1641 30.0188 83.1641 27.6768C83.1641 26.5595 83.7763 25.5748 84.5746 24.9361C83.9077 24.4568 83.5362 23.7648 83.5362 22.8601C83.5362 21.7428 84.2031 20.9975 85.1601 20.0395C84.5746 19.2675 84.2551 18.3061 84.2551 17.2181C84.2551 14.4241 86.4386 12.5881 89.4196 12.5881C90.1458 12.5883 90.8664 12.7141 91.5497 12.9601L93.3863 10.8581L95.0369 12.3215L93.413 14.1881C94.1698 15.0168 94.5877 16.0994 94.584 17.2215C94.584 20.0428 92.4013 21.8521 89.4196 21.8521C88.4346 21.8521 87.5563 21.6661 86.8107 21.3188C86.2772 21.9041 86.0391 22.1968 86.0391 22.6761C86.0391 23.4475 86.706 23.7428 87.5296 23.7428H91.2569C93.8391 23.7381 95.7291 24.9628 95.7291 27.4375ZM93.2002 27.6241C93.2002 26.3461 92.4 25.8141 91.0435 25.8141H87.9291C87.4741 25.8205 87.0196 25.7848 86.5713 25.7075C85.879 26.2408 85.6663 26.9075 85.6663 27.5975C85.6663 28.6641 86.4386 29.4868 87.5029 29.4868H91.4696C92.7474 29.4868 93.2002 28.6881 93.2002 27.6241ZM86.7313 17.2181C86.7313 18.8181 87.9557 19.7728 89.4196 19.7728C90.8834 19.7728 92.1085 18.8148 92.1085 17.2181C92.1085 15.5948 90.8841 14.6368 89.4196 14.6368C87.9551 14.6368 86.7313 15.5948 86.7313 17.2181Z"
            fill={primaryColor}
        />
        <path
            d="M95.8031 19.5088C95.8031 15.4908 98.7048 12.5901 102.725 12.5901C106.745 12.5901 109.619 15.4888 109.619 19.5088C109.619 23.5288 106.744 26.4281 102.725 26.4281C98.7055 26.4281 95.8031 23.5255 95.8031 19.5088ZM106.984 19.5088C106.984 16.7681 105.25 14.8521 102.725 14.8521C100.169 14.8521 98.4387 16.7681 98.4387 19.5088C98.4387 22.2495 100.169 24.1661 102.725 24.1661C105.254 24.1641 106.984 22.2481 106.984 19.5088Z"
            fill={primaryColor}
        />
        <path
            d="M114.015 12.8008H116.598V15.1955C117.157 13.6788 118.594 12.5881 120.564 12.5881C122.748 12.5881 124.372 13.5721 124.904 15.3288C125.437 13.8121 127.193 12.5881 129.27 12.5881C132.172 12.5881 133.982 14.5835 133.982 17.6975V26.2135H131.399V18.4135C131.399 16.2841 130.332 14.8475 128.551 14.8475C126.501 14.8475 125.277 16.2841 125.277 18.4135V26.2135H122.694V18.4135C122.694 16.2841 121.627 14.8475 119.872 14.8475C117.823 14.8475 116.598 16.2841 116.598 18.4135V26.2135H114.015V12.8008Z"
            fill={primaryColor}
        />
        <path
            d="M146.559 17.4575V26.2135H144.083V24.0315C143.444 25.4681 141.74 26.4261 139.718 26.4261C137.029 26.4261 135.165 24.7228 135.165 22.4081C135.165 19.6935 136.922 18.0968 140.383 18.0968H143.285C143.79 18.0968 144.085 17.8301 144.085 17.3515V17.2981C144.085 15.6981 142.648 14.6635 140.784 14.6635C138.92 14.6635 137.616 15.8341 137.51 17.2181H135.141C135.38 14.5835 137.696 12.5881 140.757 12.5881C144.11 12.5881 146.559 14.5308 146.559 17.4575ZM144.083 20.3321V19.9321H140.623C138.599 19.9321 137.721 20.8101 137.721 22.3275C137.721 23.5275 138.788 24.3761 140.196 24.3761C142.649 24.3755 144.083 22.8068 144.083 20.3321Z"
            fill={primaryColor}
        />
        <path
            d="M152.253 19.7995L150.575 21.3435V26.2101H147.966V6.64214H150.575V18.1228L156.485 12.8008H159.76L154.116 18.0421L160.265 26.2121H157.071L152.253 19.7995Z"
            fill={primaryColor}
        />
        <path
            d="M178.865 15.0361C176.416 15.0361 175.617 17.2181 175.617 20.1455V26.2121H173.035V12.8008H175.617V15.3821C176.23 13.5988 177.295 12.8008 179.185 12.8008H180.809V15.0361H178.865Z"
            fill={primaryColor}
        />
        <path
            d="M169.501 21.8488C169.128 23.3655 167.851 24.2175 165.881 24.2175C163.538 24.2175 161.887 22.4875 161.675 19.9595H171.926C171.953 19.7201 171.979 19.2928 171.979 18.9481C171.979 15.5421 169.769 12.5881 165.617 12.5881C161.49 12.5881 159.148 15.6748 159.148 19.4275C159.148 23.1528 161.73 26.4275 165.883 26.4275C168.906 26.4275 171.191 24.9101 171.923 22.5295L169.504 21.8501L169.501 21.8488ZM165.615 14.6635C167.824 14.6635 169.209 16.0208 169.368 18.0701H161.755C162.153 15.8341 163.511 14.6635 165.615 14.6635Z"
            fill={primaryColor}
        />
        <path
            d="M183 24.7421V24.7755C182.999 25.2182 182.823 25.6427 182.51 25.9557C182.197 26.2688 181.772 26.4449 181.329 26.4455C181.11 26.4457 180.892 26.4027 180.689 26.319C180.486 26.2352 180.302 26.1122 180.146 25.9572C179.991 25.8021 179.867 25.6179 179.783 25.4152C179.699 25.2124 179.655 24.995 179.655 24.7755V24.7441C179.655 24.5242 179.698 24.3064 179.782 24.1032C179.866 23.9 179.989 23.7153 180.145 23.5598C180.3 23.4043 180.485 23.2811 180.688 23.197C180.892 23.113 181.109 23.0699 181.329 23.0701C181.773 23.0707 182.198 23.2471 182.511 23.5606C182.824 23.8741 183 24.299 183 24.7421Z"
            fill={primaryColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M198.728 19.3778L192.482 25.6239L193.896 27.0382L200.142 20.7921L206.388 27.0382L207.802 25.6239L201.556 19.3778L208.038 12.896L206.624 11.4818L200.142 17.9636L193.66 11.4818L192.246 12.896L198.728 19.3778Z"
            fill={primaryColor}
        />
        <path
            d="M229.761 6.64214C222.87 6.64214 217.284 12.2379 217.284 19.1421C217.284 26.0441 222.87 31.6421 229.761 31.6421C236.653 31.6421 242.239 26.0463 242.239 19.1421C242.236 12.2379 236.651 6.64214 229.761 6.64214ZM226.643 24.3486H224.564V13.9332H226.643V24.3486ZM234.959 24.3486H228.721V22.2665H234.959V24.3486ZM234.959 20.182H228.721V18.0999H234.959V20.182ZM234.959 16.0154H228.721V13.9332H234.959V16.0154Z"
            fill={primaryColor}
        />
    </svg>
);

ElementorLogoMobile.propTypes = {
    primaryColor: string,
};

export default ElementorLogoMobile;
