import React from 'react';
import classnames from 'classnames';
import { bool } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import pathfinder from '@fiverr-private/pathfinder';
import { LOGO_MAKER_SOURCE } from '../../../constants';
import Link from '../../Links/Link';
import { LINKS } from '../constants';
import { bigQueryClickEvent, mixpanelInteractionEvent } from '../utils';

const GoToFiverrLink = ({ isThemed = false }) => {
    const { userId } = getContext();
    const path = pathfinder('root', {}, { query: { source: LOGO_MAKER_SOURCE } });

    return (
        <Link
            href={path}
            target="_blank"
            className={classnames('fiverr-link nav-link', { 'themed-link': isThemed })}
            bigQueryData={bigQueryClickEvent(userId, LINKS.GO_TO_FIVERR)}
            mpData={mixpanelInteractionEvent(LINKS.GO_TO_FIVERR)}
        >
            <I18n k="header.go_to_fiverr" />
        </Link>
    );
};

GoToFiverrLink.propTypes = {
    isThemed: bool,
};

export default GoToFiverrLink;
