import { INTEGRATIONS } from '../../constants';

const ALL_INTEGRATIONS = [
    INTEGRATIONS.WORDPRESS,
    INTEGRATIONS.W3SCHOOLS,
    INTEGRATIONS.ADALO,
    INTEGRATIONS.WOO,
    INTEGRATIONS.ELEMENTOR,
] as const;
type Integration = (typeof ALL_INTEGRATIONS)[number];
const REGEX = new RegExp(`/logo-maker/(${ALL_INTEGRATIONS.join('|')})/?`);

const getLogoMakerIntegrationFromPath = (path: string): Integration | null => {
    const match = path.match(REGEX);
    if (match) {
        return match[1] as Integration;
    }
    return null;
};

export default getLogoMakerIntegrationFromPath;
