import React from 'react';
import { string } from 'prop-types';
import { DARK_COLOR } from '../../constants';

const W3schoolsLogoMobile = ({ primaryColor = DARK_COLOR }) => (
    <svg width="284" height="32" viewBox="0 0 284 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M70.4508 9.10229H68.7596C67.0406 9.10229 65.8841 9.63358 65.0404 11.4795V9.10229H58.73C57.011 9.10229 55.8544 9.63358 55.0108 11.4795V9.10229H49.853V24.7453H55.0077V16.8294C55.0077 14.6394 55.9448 13.3255 57.6955 13.3255H59.8834V24.7453H65.0435V16.8294C65.0435 14.6394 65.9814 13.3255 67.7313 13.3255H70.4508V9.10229Z"
                fill={primaryColor}
            />
            <path
                d="M24.0359 24.7453H28.5678L34.2254 9.10229H29.0368L26.2864 18.206L23.4734 9.10229H18.3157L24.0359 24.7453Z"
                fill={primaryColor}
            />
            <path
                d="M2.90645 24.7454H8.03484V13.3255H12.9114V24.7454H18.0011V9.10236H8.03484V8.13183C8.03484 7.0685 8.7827 6.40807 9.97093 6.40807H12.9067V2.18799H9.12109C5.40806 2.18799 2.90645 4.47474 2.90645 7.8194V9.10236H0V13.3255H2.90645V24.7454Z"
                fill={primaryColor}
            />
            <path
                d="M48.7908 16.6105C48.7908 13.3254 46.8184 8.88257 40.8826 8.88257C35.2876 8.88257 33.0378 13.2627 33.0378 16.9252C33.0378 20.6481 35.6005 24.9973 41.1955 24.9973C44.5083 24.9973 47.2904 23.3385 48.4153 20.6171L43.8524 19.3342C43.5395 20.3395 42.5707 20.8986 41.1955 20.8986C39.3514 20.8986 38.2597 19.8933 37.9762 18.1456H48.7282C48.7591 17.7999 48.7908 17.3305 48.7908 16.6105ZM37.9746 15.1087C38.2837 13.2318 39.2556 12.5118 40.8811 12.5118C42.6318 12.5118 43.6006 13.7638 43.7257 15.1087H37.9746Z"
                fill={primaryColor}
            />
            <path d="M75.3267 2H78.3181V24.7027H75.3267V2Z" fill={primaryColor} />
            <path
                d="M79.7173 16.9253C79.7173 12.2644 83.0788 8.89966 87.7359 8.89966C92.393 8.89966 95.7228 12.2621 95.7228 16.9253C95.7228 21.5885 92.3922 24.9517 87.7351 24.9517C83.078 24.9517 79.7173 21.5846 79.7173 16.9253ZM92.6704 16.9253C92.6704 13.7461 90.6663 11.5236 87.7359 11.5236C84.7754 11.5236 82.7705 13.7461 82.7705 16.9253C82.7705 20.1045 84.7792 22.3278 87.7359 22.3278C90.6663 22.3255 92.6704 20.1029 92.6704 16.9253Z"
                fill={primaryColor}
            />
            <path
                d="M110.899 26.1227C110.899 28.9322 109.142 31.0001 106.088 31.0001H101.277C98.0697 31.0001 96.343 29.117 96.343 26.4003C96.343 25.1042 97.0522 23.962 97.977 23.2211C97.2044 22.6651 96.7741 21.8624 96.7741 20.813C96.7741 19.5168 97.5467 18.6523 98.6553 17.541C97.977 16.6455 97.607 15.5303 97.607 14.2682C97.607 11.0272 100.136 8.89743 103.59 8.89743C104.431 8.89759 105.266 9.04359 106.057 9.32895L108.185 6.89062L110.097 8.58809L108.216 10.7534C109.093 11.7147 109.577 12.9705 109.573 14.2721C109.573 17.5448 107.044 19.6437 103.59 19.6437C102.449 19.6437 101.431 19.4279 100.567 19.025C99.9494 19.704 99.6736 20.0435 99.6736 20.5995C99.6736 21.4943 100.446 21.8368 101.4 21.8368H105.718C108.71 21.8314 110.899 23.252 110.899 26.1227ZM107.97 26.3392C107.97 24.8567 107.042 24.2396 105.471 24.2396H101.863C101.336 24.247 100.81 24.2056 100.29 24.1159C99.4882 24.7345 99.2417 25.5079 99.2417 26.3083C99.2417 27.5456 100.136 28.4999 101.369 28.4999H105.965C107.445 28.4999 107.97 27.5734 107.97 26.3392ZM100.476 14.2682C100.476 16.1242 101.894 17.2316 103.59 17.2316C105.286 17.2316 106.705 16.1204 106.705 14.2682C106.705 12.3852 105.286 11.2739 103.59 11.2739C101.893 11.2739 100.476 12.3852 100.476 14.2682Z"
                fill={primaryColor}
            />
            <path
                d="M110.985 16.9253C110.985 12.2644 114.347 8.89966 119.004 8.89966C123.661 8.89966 126.991 12.2621 126.991 16.9253C126.991 21.5885 123.66 24.9517 119.004 24.9517C114.347 24.9517 110.985 21.5846 110.985 16.9253ZM123.938 16.9253C123.938 13.7461 121.929 11.5236 119.004 11.5236C116.042 11.5236 114.038 13.7461 114.038 16.9253C114.038 20.1045 116.042 22.3278 119.004 22.3278C121.933 22.3255 123.938 20.1029 123.938 16.9253Z"
                fill={primaryColor}
            />
            <path
                d="M132.083 9.14415H135.076V11.922C135.723 10.1626 137.388 8.89746 139.67 8.89746C142.2 8.89746 144.081 10.0389 144.697 12.0766C145.315 10.3173 147.35 8.89746 149.755 8.89746C153.117 8.89746 155.214 11.212 155.214 14.8243V24.7028H152.222V15.6548C152.222 13.1848 150.986 11.5183 148.923 11.5183C146.548 11.5183 145.129 13.1848 145.129 15.6548V24.7028H142.138V15.6548C142.138 13.1848 140.902 11.5183 138.868 11.5183C136.494 11.5183 135.076 13.1848 135.076 15.6548V24.7028H132.083V9.14415Z"
                fill={primaryColor}
            />
            <path
                d="M169.785 14.5459V24.7028H166.916V22.1717C166.176 23.8383 164.202 24.9495 161.859 24.9495C158.744 24.9495 156.585 22.9737 156.585 20.2887C156.585 17.1396 158.62 15.2875 162.63 15.2875H165.991C166.577 15.2875 166.918 14.9782 166.918 14.4229V14.3611C166.918 12.5051 165.254 11.3048 163.094 11.3048C160.935 11.3048 159.424 12.6628 159.301 14.2683H156.557C156.834 11.212 159.517 8.89746 163.063 8.89746C166.947 8.89746 169.785 11.151 169.785 14.5459ZM166.916 17.8805V17.4165H162.907C160.563 17.4165 159.546 18.435 159.546 20.1951C159.546 21.5871 160.782 22.5715 162.414 22.5715C165.255 22.5708 166.916 20.7511 166.916 17.8805Z"
                fill={primaryColor}
            />
            <path
                d="M176.38 17.2625L174.437 19.0535V24.6989H171.415V2H174.437V15.3176L181.284 9.14405H185.077L178.539 15.224L185.663 24.7012H181.962L176.38 17.2625Z"
                fill={primaryColor}
            />
            <path
                d="M207.21 11.737C204.372 11.737 203.447 14.2681 203.447 17.6639V24.7012H200.456V9.14404H203.447V12.1384C204.157 10.0697 205.391 9.14404 207.58 9.14404H209.461V11.737H207.21Z"
                fill={primaryColor}
            />
            <path
                d="M196.362 19.6398C195.93 21.3992 194.45 22.3875 192.168 22.3875C189.454 22.3875 187.542 20.3807 187.295 17.4482H199.171C199.202 17.1706 199.232 16.6749 199.232 16.2751C199.232 12.3241 196.673 8.89746 191.862 8.89746C187.081 8.89746 184.368 12.478 184.368 16.8311C184.368 21.1525 187.359 24.9511 192.171 24.9511C195.672 24.9511 198.319 23.191 199.167 20.4294L196.365 19.6414L196.362 19.6398ZM191.86 11.3048C194.419 11.3048 196.023 12.8794 196.208 15.2566H187.388C187.85 12.6628 189.423 11.3048 191.86 11.3048Z"
                fill={primaryColor}
            />
            <path
                d="M212 22.9959V23.0346C212 23.5482 211.795 24.0406 211.433 24.4037C211.07 24.7669 210.578 24.9712 210.065 24.9718C209.81 24.9721 209.558 24.9222 209.323 24.825C209.088 24.7278 208.874 24.5852 208.694 24.4054C208.514 24.2255 208.371 24.0118 208.273 23.7766C208.175 23.5414 208.125 23.2893 208.125 23.0346V22.9982C208.124 22.7431 208.174 22.4905 208.272 22.2548C208.369 22.019 208.512 21.8048 208.692 21.6245C208.873 21.4441 209.086 21.3011 209.322 21.2036C209.558 21.1061 209.81 21.0561 210.065 21.0564C210.578 21.057 211.071 21.2616 211.433 21.6253C211.796 21.989 212 22.4819 212 22.9959Z"
                fill={primaryColor}
            />
            <path d="M224 10L238 24" stroke={primaryColor} strokeWidth="2" />
            <path d="M238 10L224 24" stroke={primaryColor} strokeWidth="2" />
            <path
                d="M278.226 6.62946C278.226 6.62946 279.069 7.53348 280.248 7.53348C281.179 7.53348 281.855 6.97098 281.855 6.20089C281.855 5.2567 280.991 4.82143 279.946 4.82143H279.33L278.962 3.99107L280.596 2.0558C280.944 1.64063 281.239 1.37946 281.239 1.37946C281.239 1.37946 280.978 1.39286 280.449 1.39286H277.79V0H283.234V1.01786L281.051 3.54241C282.284 3.71652 283.489 4.59375 283.489 6.14063C283.489 7.66071 282.337 9.06696 280.348 9.06696C278.453 9.06696 277.402 7.875 277.402 7.875L278.226 6.62946Z"
                fill={primaryColor}
            />
            <path
                d="M267.605 8.51127L274.797 21.3148L278.346 14.9867L271.556 2.89966H263.654L260.379 8.73225L257.105 2.89966H250L260.339 21.3148L260.379 21.2479L260.42 21.3148L267.605 8.51127Z"
                fill={primaryColor}
            />
            <path
                d="M250.074 29.9933H251.125C251.125 30.3013 251.393 30.5959 251.868 30.5959C252.31 30.5959 252.605 30.375 252.605 30.0803C252.605 29.8259 252.411 29.712 252.076 29.6384L251.473 29.4843C250.455 29.2165 250.194 28.6741 250.194 28.0982C250.194 27.3951 250.891 26.7522 251.875 26.7522C252.679 26.7522 253.589 27.1607 253.576 28.1651H252.511C252.511 27.8571 252.23 27.6629 251.909 27.6629C251.56 27.6629 251.319 27.8638 251.319 28.1451C251.319 28.3794 251.54 28.5134 251.801 28.5736L252.525 28.7678C253.542 29.029 253.723 29.6651 253.723 30.0803C253.723 30.9977 252.806 31.5 251.888 31.5C250.998 31.5 250.094 30.9576 250.074 29.9933Z"
                fill={primaryColor}
            />
            <path
                d="M254.654 29.1227C254.654 27.6897 255.806 26.7522 257.112 26.7522C257.895 26.7522 258.531 27.1004 258.946 27.6227L258.156 28.2522C257.922 27.9709 257.54 27.7968 257.132 27.7968C256.348 27.7968 255.786 28.3593 255.786 29.1227C255.786 29.8794 256.348 30.4553 257.132 30.4553C257.54 30.4553 257.922 30.2812 258.156 30L258.946 30.6294C258.531 31.1518 257.895 31.5 257.112 31.5C255.806 31.5 254.654 30.5558 254.654 29.1227Z"
                fill={primaryColor}
            />
            <path
                d="M264.357 28.8482V31.3794H263.232V28.9285C263.232 28.2053 262.797 27.8169 262.295 27.8169C261.779 27.8169 261.143 28.1183 261.143 28.9955V31.3861H260.018V24.4084H261.15V27.5491C261.371 27 262.1 26.7455 262.549 26.7455C263.714 26.7522 264.357 27.5357 264.357 28.8482Z"
                fill={primaryColor}
            />
            <path
                d="M265.536 29.1227C265.536 27.6897 266.647 26.7522 267.933 26.7522C269.219 26.7522 270.344 27.6897 270.344 29.1227C270.344 30.5558 269.219 31.5 267.933 31.5C266.647 31.5 265.536 30.5558 265.536 29.1227ZM269.212 29.1227C269.212 28.3459 268.629 27.7968 267.933 27.7968C267.237 27.7968 266.667 28.3459 266.667 29.1227C266.667 29.9129 267.237 30.4553 267.933 30.4553C268.629 30.4553 269.212 29.9129 269.212 29.1227Z"
                fill={primaryColor}
            />
            <path
                d="M271.301 29.1227C271.301 27.6897 272.413 26.7522 273.699 26.7522C274.984 26.7522 276.109 27.6897 276.109 29.1227C276.109 30.5558 274.984 31.5 273.699 31.5C272.42 31.5 271.301 30.5558 271.301 29.1227ZM274.978 29.1227C274.978 28.3459 274.395 27.7968 273.699 27.7968C273.002 27.7968 272.433 28.3459 272.433 29.1227C272.433 29.9129 273.002 30.4553 273.699 30.4553C274.402 30.4553 274.978 29.9129 274.978 29.1227Z"
                fill={primaryColor}
            />
            <path d="M277.355 31.3861V24.4084H278.48V31.3861H277.355Z" fill={primaryColor} />
            <path
                d="M279.705 29.9933H280.757C280.757 30.3013 281.025 30.5959 281.5 30.5959C281.942 30.5959 282.237 30.375 282.237 30.0803C282.237 29.8259 282.042 29.712 281.708 29.6384L281.105 29.4843C280.087 29.2165 279.826 28.6741 279.826 28.0982C279.826 27.3951 280.522 26.7522 281.507 26.7522C282.31 26.7522 283.221 27.1607 283.208 28.1651H282.136C282.136 27.8571 281.855 27.6629 281.533 27.6629C281.185 27.6629 280.944 27.8638 280.944 28.1451C280.944 28.3794 281.165 28.5134 281.426 28.5736L282.15 28.7678C283.167 29.029 283.348 29.6651 283.348 30.0803C283.348 30.9977 282.431 31.5 281.513 31.5C280.623 31.5 279.725 30.9576 279.705 29.9933Z"
                fill={primaryColor}
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="283.489" height="31.5" fill={primaryColor} />
            </clipPath>
        </defs>
    </svg>
);

W3schoolsLogoMobile.propTypes = {
    primaryColor: string,
};

export default W3schoolsLogoMobile;
