import FiverrLogo from '@fiverr-private/icons/logos/fiverr_logomaker_logo';
import { INTEGRATIONS } from '../../constants';
import WordpressLogoDesktop from './Integrations/Wordpress/DesktopLogo';
import WordpressLogoMobile from './Integrations/Wordpress/MobileLogo';
import W3schoolsLogoDesktop from './Integrations/W3schools/DesktopLogo';
import W3schoolsLogoMobile from './Integrations/W3schools/MobileLogo';
import AdaloLogoDesktop from './Integrations/Adalo/DesktopLogo';
import AdaloLogoMobile from './Integrations/Adalo/MobileLogo';
import ElementorLogoDesktop from './Integrations/Elementor/DesktopLogo';
import ElementorLogoMobile from './Integrations/Elementor/MobileLogo';
import WooLogo from './Integrations/Woo';

export * from './colors';

export const DESKTOP_LOGO_MAP = {
    [INTEGRATIONS.NONE]: FiverrLogo,
    [INTEGRATIONS.WORDPRESS]: WordpressLogoDesktop,
    [INTEGRATIONS.W3SCHOOLS]: W3schoolsLogoDesktop,
    [INTEGRATIONS.ADALO]: AdaloLogoDesktop,
    [INTEGRATIONS.ELEMENTOR]: ElementorLogoDesktop,
    [INTEGRATIONS.WOO]: WooLogo,
};

export const MOBILE_LOGO_MAP = {
    [INTEGRATIONS.NONE]: FiverrLogo,
    [INTEGRATIONS.WORDPRESS]: WordpressLogoMobile,
    [INTEGRATIONS.W3SCHOOLS]: W3schoolsLogoMobile,
    [INTEGRATIONS.ADALO]: AdaloLogoMobile,
    [INTEGRATIONS.ELEMENTOR]: ElementorLogoMobile,
    [INTEGRATIONS.WOO]: WooLogo,
};
