import React, { useRef, useContext } from 'react';
import classnames from 'classnames';
import { translate } from '@fiverr-private/i18n-react';
import LogoMakerContext from '../../lib/logoMakerContext';
import { LOGO_MAKER_TRANSLATION_PREFIX } from '../../constants';
import LogoMakerMenu from './LogoMakerMenu';

import classes from './style.module.scss';

const LogoMakerExplore = () => {
    const { logoIdeasMenuIsOpened, setLogoIdeasMenuIsOpened } = useContext(LogoMakerContext);
    const contentRef = useRef();
    const triggerRef = useRef();

    const openHandler = () => {
        setLogoIdeasMenuIsOpened(true);
    };

    const closeHandler = (target) => (e) => {
        const trigger = triggerRef.current;
        const content = contentRef.current;
        let shouldClose = true;
        if (e.relatedTarget instanceof Node) {
            switch (target) {
                case trigger:
                    shouldClose = !content.contains(e.relatedTarget);
                    break;
                case content:
                    shouldClose = !trigger.contains(e.relatedTarget);
                    break;
                default:
                    shouldClose = true;
            }
        }
        if (shouldClose) {
            setLogoIdeasMenuIsOpened(false);
        }
    };

    return (
        <>
            <div
                className={classnames(classes.linksTrigger, { [classes.opened]: logoIdeasMenuIsOpened })}
                ref={triggerRef}
                onMouseEnter={openHandler}
                onMouseLeave={closeHandler(triggerRef.current)}
            >
                <div className="nav-link themed-link">{translate(`${LOGO_MAKER_TRANSLATION_PREFIX}.explore`)}</div>
            </div>
            <div
                className={classnames(classes.linksMenu, { [classes.opened]: logoIdeasMenuIsOpened })}
                ref={contentRef}
                onMouseLeave={closeHandler(contentRef.current)}
            >
                <LogoMakerMenu />
            </div>
        </>
    );
};

export default LogoMakerExplore;
