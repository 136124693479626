import pathfinder from '@fiverr-private/pathfinder';
import { URI } from '@fiverr-private/futile';
import { imgSrc, imgSrcSet } from '../../../lib/image';
import { LOGO_MAKER_SOURCE, LOGO_MAKER_TRANSLATION_PREFIX } from '../../../constants';

const IMAGE_2X = '9dbad8a2f2c9922a6e969eb430b94809-1618568647341/popular-logos-2x.png';
const IMAGE = '41dfa7f913fe068db9b2b3f59907a607-1618568680789/popular-logos.png';
const IMAGE_WIDTH = 454;

export const IMAGE_SRC_SET = imgSrcSet({ width: IMAGE_WIDTH, img: IMAGE, retinaImg: IMAGE_2X });
export const IMAGE_SRC = imgSrc({ img: IMAGE });

const createLogoPath = ({ tag, type }) => pathfinder('logo_maker_buyer_ideas_page', { tag, type });

const createCategoryPath = (id) =>
    pathfinder(
        'short_category_sub_category',
        { category_id: 'graphics-design', id },
        { query: { source: LOGO_MAKER_SOURCE } }
    );

export const createPopularLogos = () => [
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.gaming_logos`,
        bigQuery: 'gaming',
        mixpanel: 'Gaming logos',
        path: createLogoPath({ tag: 'gaming', type: 'industry' }),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.sports_logos`,
        bigQuery: 'sports',
        mixpanel: 'Sports logos',
        path: createLogoPath({ tag: 'sports', type: 'tag' }),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.restaurant_logos`,
        bigQuery: 'restaurant',
        mixpanel: 'Restaurant logos',
        path: createLogoPath({ tag: 'restaurants', type: 'industry' }),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.food_logos`,
        bigQuery: 'food and drinks',
        mixpanel: 'Food & Drinks logos',
        path: createLogoPath({ tag: 'food', type: 'tag' }),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.real_estate_logos`,
        bigQuery: 'real estate',
        mixpanel: 'Real Estate logos',
        path: createLogoPath({ tag: 'real-estate', type: 'industry' }),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.fashion_logos`,
        bigQuery: 'fashion',
        mixpanel: 'Fashion logos',
        path: createLogoPath({ tag: 'fashion-and-apparel', type: 'industry' }),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.law_logos`,
        bigQuery: 'law',
        mixpanel: 'Law logos',
        path: createLogoPath({ tag: 'law', type: 'industry' }),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.music_logos`,
        bigQuery: 'music',
        mixpanel: 'Music logos',
        path: createLogoPath({ tag: 'music', type: 'industry' }),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.medical_logos`,
        bigQuery: 'medical',
        mixpanel: 'Medical logos',
        path: createLogoPath({ tag: 'medical', type: 'tag' }),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.youtube_logos`,
        bigQuery: 'youtube',
        mixpanel: 'Youtube logos',
        path: createLogoPath({ tag: 'youtube', type: 'tag' }),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.clothing_logos`,
        bigQuery: 'clothing',
        mixpanel: 'Clothing logos',
        path: createLogoPath({ tag: 'clothing', type: 'tag' }),
    },
];

export const createSolutions = () => [
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.hire_a_designer`,
        bigQuery: 'hire a designer',
        mixpanel: 'Hire a designer',
        path: createCategoryPath('creative-logo-design'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.branding_hub`,
        bigQuery: 'branding hub',
        mixpanel: 'Branding hub',
        path: pathfinder('logo_maker_brand_solutions_page'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.business_cards_design`,
        bigQuery: 'business cards design',
        mixpanel: 'Business cards design',
        path: createCategoryPath('sample-business-cards-design'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.social_media_design`,
        bigQuery: 'social media design',
        mixpanel: 'Social media design',
        path: createCategoryPath('social-media-design'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.email_design`,
        bigQuery: 'email design',
        mixpanel: 'Email design',
        path: createCategoryPath('email-design'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.website_design`,
        bigQuery: 'website design',
        mixpanel: 'Website design',
        path: createCategoryPath('website-design'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.apparel_and_merchandise_design`,
        bigQuery: 'apparel and merchandise design',
        mixpanel: 'Apparel and merchandise design',
        path: createCategoryPath('t-shirts'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.promotional_design`,
        bigQuery: 'promotional design',
        mixpanel: 'Promotional design',
        path: pathfinder('gig', { id: 'promotional-design' }, { query: { source: LOGO_MAKER_SOURCE } }),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.fiverr_freelance_services_marketplace`,
        bigQuery: 'fiverr freelance services marketplace',
        mixpanel: 'Fiverr freelance services marketplace',
        path: pathfinder('homepage', {}, { query: { source: LOGO_MAKER_SOURCE } }),
    },
];

export const createLearnDiscover = () => [
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.about_us`,
        bigQuery: 'about us',
        mixpanel: 'About us',
        path: pathfinder('logo_maker_about_page'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.meet_the_designers`,
        bigQuery: 'meet the designers',
        mixpanel: 'Meet the Designers',
        path: pathfinder('logo_maker_meet_the_designers'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.how_it_works`,
        bigQuery: 'how it works',
        mixpanel: 'How it works',
        path: pathfinder('logo_maker_how_logo_maker_works_page'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.design_branding_guides`,
        bigQuery: 'design & branding guides',
        mixpanel: 'Design & Branding guides',
        path: pathfinder('education_hub_render'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.logo_ideas_inspirations`,
        bigQuery: 'logo ideas & inspirations',
        mixpanel: 'Logo ideas & inspirations',
        path: pathfinder('logo_maker_discover_logo_ideas_page'),
    },
    {
        copiesKey: `${LOGO_MAKER_TRANSLATION_PREFIX}.help_support`,
        bigQuery: 'help & support',
        mixpanel: 'Help & Support',
        path: new URI('https://help.fiverr.com/hc/en-us/categories/4419466074513'),
    },
];
