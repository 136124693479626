import React, { useContext } from 'react';
import { Button } from '@fiverr-private/fit';
import * as HeaderPropTypes from '../../PropTypes';
import withContext from '../../lib/withContext';
import Nav from '../../lib/Nav';
import { INTEGRATIONS } from '../../constants';
import GoToFiverrLink from '../../components/LogoMakerLinks/GoToFiverrLink';
import LogoMakerLayout from '../../components/LogoMakerLayout';
import LogoMakerContext from '../../lib/logoMakerContext';
import withLogoMakerContext from '../../lib/withLogoMakerContext';
import { MakeYourLogoLink } from '../../components/LogoMakerLinks/MakeYourLogoLink';
import LoginLink from '../../components/LoginLink';
import JoinLink from '../../components/JoinLink';
import TestimonialsLink from '../../components/LogoMakerLinks/TestimonialsLink';
import PricingLink from '../../components/LogoMakerLinks/PricingLink';
import LogoMakerExplore from '../../components/LogoMakerExplore';
import TutorialsLink from '../../components/LogoMakerLinks/TutorialsLink';

const LoggedOutLogoMaker = ({ showSeoLinks, showJoinLink = true }) => {
    const { isTransparent, isSticky } = useContext(LogoMakerContext);
    const joinLinkColor = isTransparent ? Button.COLORS.WHITE : Button.COLORS.BLUE;

    return (
        <LogoMakerLayout>
            <Nav>
                {showSeoLinks && (
                    <>
                        <Nav.Item displayFromMD>
                            <LogoMakerExplore />
                        </Nav.Item>
                        <Nav.Item displayFromMD>
                            <TestimonialsLink />
                        </Nav.Item>
                        <Nav.Item displayFromMD>
                            <TutorialsLink />
                        </Nav.Item>
                        <Nav.Item displayFromMD>
                            <PricingLink />
                        </Nav.Item>
                    </>
                )}
                {!showSeoLinks && (
                    <Nav.Item displayFromMD>
                        <GoToFiverrLink isThemed />
                    </Nav.Item>
                )}
                <Nav.VerticalDivider displayFromMD />
                <Nav.Item displayFromMD>
                    <LoginLink isThemed />
                </Nav.Item>
                {showJoinLink && !isSticky && (
                    <Nav.Item displayFromMD>
                        <JoinLink isThemed color={joinLinkColor} textColor={Button.TEXT_COLORS.BLUE} />
                    </Nav.Item>
                )}
                {isSticky && (
                    <Nav.Item displayFromMD>
                        <MakeYourLogoLink />
                    </Nav.Item>
                )}
            </Nav>
        </LogoMakerLayout>
    );
};

LoggedOutLogoMaker.propTypes = HeaderPropTypes.logoMakerProps;

/**
 * @param {HeaderProps} props
 * @returns {React.ReactElement} Header
 */
const LoggedOutLogoMakerWithContext = withContext(withLogoMakerContext(LoggedOutLogoMaker));

LoggedOutLogoMakerWithContext.INTEGRATIONS = INTEGRATIONS;

export default LoggedOutLogoMakerWithContext;
