import React from 'react';
import { string } from 'prop-types';
import { DARK_COLOR } from '../../constants';

const AdaloLogoMobile = ({ primaryColor = DARK_COLOR }) => (
    <svg width="239" height="27" viewBox="0 0 239 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M60.8137 7.7648H59.3539C57.87 7.7648 56.8717 8.2228 56.1434 9.81413V7.7648H50.6962C49.2124 7.7648 48.214 8.2228 47.4858 9.81413V7.7648H43.0335V21.2501H47.4831V14.4261C47.4831 12.5381 48.292 11.4055 49.8032 11.4055H51.6919V21.2501H56.1461V14.4261C56.1461 12.5381 56.9557 11.4055 58.4662 11.4055H60.8137V7.7648Z"
            fill={primaryColor}
        />
        <path
            d="M20.7478 21.2501H24.6599L29.5436 7.7648H25.0647L22.6905 15.6128L20.2623 7.7648H15.8101L20.7478 21.2501Z"
            fill={primaryColor}
        />
        <path
            d="M2.50887 21.2501H6.93572V11.4055H11.1452V21.2501H15.5387V7.7648H6.93572V6.92814C6.93572 6.01147 7.58128 5.44214 8.60697 5.44214H11.1412V1.80414H7.87338C4.66828 1.80414 2.50887 3.77547 2.50887 6.6588V7.7648H0V11.4055H2.50887V21.2501Z"
            fill={primaryColor}
        />
        <path
            d="M42.1166 14.2375C42.1166 11.4055 40.414 7.57547 35.2902 7.57547C30.4605 7.57547 28.5185 11.3515 28.5185 14.5088C28.5185 17.7181 30.7306 21.4675 35.5603 21.4675C38.42 21.4675 40.8215 20.0375 41.7925 17.6915L37.8538 16.5855C37.5837 17.4521 36.7474 17.9341 35.5603 17.9341C33.9684 17.9341 33.0261 17.0675 32.7813 15.5608H42.0626C42.0892 15.2628 42.1166 14.8581 42.1166 14.2375ZM32.78 12.9428C33.0468 11.3248 33.8857 10.7041 35.2889 10.7041C36.8001 10.7041 37.6364 11.7835 37.7444 12.9428H32.78Z"
            fill={primaryColor}
        />
        <path d="M65.0224 1.64214H67.6048V21.2135H65.0224V1.64214Z" fill={primaryColor} />
        <path
            d="M68.8125 14.5088C68.8125 10.4908 71.7141 7.59013 75.7341 7.59013C79.7542 7.59013 82.6285 10.4888 82.6285 14.5088C82.6285 18.5288 79.7535 21.4281 75.7335 21.4281C71.7134 21.4281 68.8125 18.5255 68.8125 14.5088ZM79.9936 14.5088C79.9936 11.7681 78.2637 9.85214 75.7341 9.85214C73.1786 9.85214 71.448 11.7681 71.448 14.5088C71.448 17.2495 73.1819 19.1661 75.7341 19.1661C78.2637 19.1641 79.9936 17.2481 79.9936 14.5088Z"
            fill={primaryColor}
        />
        <path
            d="M95.729 22.4374C95.729 24.8594 94.2119 26.6421 91.5763 26.6421H87.4229C84.6546 26.6421 83.1641 25.0188 83.1641 22.6768C83.1641 21.5594 83.7763 20.5748 84.5746 19.9361C83.9077 19.4568 83.5362 18.7648 83.5362 17.8601C83.5362 16.7428 84.2031 15.9975 85.1601 15.0395C84.5746 14.2675 84.2551 13.3061 84.2551 12.2181C84.2551 9.42413 86.4385 7.58814 89.4195 7.58814C90.1457 7.58828 90.8664 7.71414 91.5496 7.96014L93.3862 5.85814L95.0368 7.32147L93.4129 9.18813C94.1697 10.0168 94.5876 11.0994 94.584 12.2215C94.584 15.0428 92.4012 16.8521 89.4195 16.8521C88.4345 16.8521 87.5562 16.6661 86.8107 16.3188C86.2771 16.9041 86.0391 17.1968 86.0391 17.6761C86.0391 18.4475 86.706 18.7428 87.5296 18.7428H91.2568C93.8391 18.7381 95.729 19.9628 95.729 22.4374ZM93.2002 22.6241C93.2002 21.3461 92.3999 20.8141 91.0434 20.8141H87.929C87.4741 20.8205 87.0196 20.7848 86.5712 20.7074C85.879 21.2408 85.6663 21.9074 85.6663 22.5974C85.6663 23.6641 86.4385 24.4868 87.5029 24.4868H91.4696C92.7473 24.4868 93.2002 23.6881 93.2002 22.6241ZM86.7313 12.2181C86.7313 13.8181 87.9557 14.7728 89.4195 14.7728C90.8834 14.7728 92.1085 13.8148 92.1085 12.2181C92.1085 10.5948 90.884 9.6368 89.4195 9.6368C87.955 9.6368 86.7313 10.5948 86.7313 12.2181Z"
            fill={primaryColor}
        />
        <path
            d="M95.8031 14.5088C95.8031 10.4908 98.7048 7.59013 102.725 7.59013C106.745 7.59013 109.619 10.4888 109.619 14.5088C109.619 18.5288 106.744 21.4281 102.725 21.4281C98.7055 21.4281 95.8031 18.5255 95.8031 14.5088ZM106.984 14.5088C106.984 11.7681 105.25 9.85214 102.725 9.85214C100.169 9.85214 98.4387 11.7681 98.4387 14.5088C98.4387 17.2495 100.169 19.1661 102.725 19.1661C105.254 19.1641 106.984 17.2481 106.984 14.5088Z"
            fill={primaryColor}
        />
        <path
            d="M114.016 7.8008H116.598V10.1955C117.157 8.6788 118.594 7.58813 120.564 7.58813C122.748 7.58813 124.372 8.57214 124.904 10.3288C125.437 8.81214 127.193 7.58813 129.27 7.58813C132.172 7.58813 133.982 9.58347 133.982 12.6975V21.2135H131.399V13.4135C131.399 11.2841 130.332 9.84747 128.551 9.84747C126.501 9.84747 125.277 11.2841 125.277 13.4135V21.2135H122.694V13.4135C122.694 11.2841 121.627 9.84747 119.872 9.84747C117.823 9.84747 116.598 11.2841 116.598 13.4135V21.2135H114.016V7.8008Z"
            fill={primaryColor}
        />
        <path
            d="M146.559 12.4575V21.2135H144.083V19.0315C143.444 20.4681 141.74 21.4261 139.718 21.4261C137.029 21.4261 135.165 19.7228 135.165 17.4081C135.165 14.6935 136.922 13.0968 140.383 13.0968H143.285C143.79 13.0968 144.085 12.8301 144.085 12.3515V12.2981C144.085 10.6981 142.648 9.66347 140.784 9.66347C138.92 9.66347 137.616 10.8341 137.51 12.2181H135.141C135.38 9.58347 137.696 7.58813 140.757 7.58813C144.11 7.58813 146.559 9.5308 146.559 12.4575ZM144.083 15.3321V14.9321H140.623C138.599 14.9321 137.721 15.8101 137.721 17.3275C137.721 18.5275 138.788 19.3761 140.196 19.3761C142.649 19.3755 144.083 17.8068 144.083 15.3321Z"
            fill={primaryColor}
        />
        <path
            d="M152.253 14.7995L150.575 16.3435V21.2101H147.966V1.64214H150.575V13.1228L156.485 7.8008H159.76L154.116 13.0421L160.265 21.2121H157.071L152.253 14.7995Z"
            fill={primaryColor}
        />
        <path
            d="M178.865 10.0361C176.416 10.0361 175.617 12.2181 175.617 15.1455V21.2121H173.035V7.8008H175.617V10.3821C176.23 8.5988 177.295 7.8008 179.185 7.8008H180.808V10.0361H178.865Z"
            fill={primaryColor}
        />
        <path
            d="M169.501 16.8488C169.128 18.3655 167.851 19.2175 165.881 19.2175C163.538 19.2175 161.887 17.4875 161.675 14.9595H171.926C171.953 14.7201 171.979 14.2928 171.979 13.9481C171.979 10.5421 169.769 7.58813 165.617 7.58813C161.49 7.58813 159.148 10.6748 159.148 14.4275C159.148 18.1528 161.73 21.4274 165.883 21.4274C168.906 21.4274 171.19 19.9101 171.923 17.5295L169.504 16.8501L169.501 16.8488ZM165.615 9.66346C167.824 9.66346 169.208 11.0208 169.368 13.0701H161.755C162.153 10.8341 163.511 9.66346 165.615 9.66346Z"
            fill={primaryColor}
        />
        <path
            d="M183 19.7421V19.7755C182.999 20.2182 182.823 20.6427 182.51 20.9557C182.197 21.2688 181.772 21.4449 181.329 21.4455C181.11 21.4457 180.892 21.4027 180.689 21.319C180.486 21.2352 180.302 21.1122 180.146 20.9572C179.991 20.8021 179.867 20.6179 179.783 20.4152C179.699 20.2124 179.655 19.995 179.655 19.7755V19.7441C179.655 19.5242 179.698 19.3064 179.782 19.1032C179.866 18.9 179.989 18.7153 180.145 18.5598C180.3 18.4043 180.485 18.2811 180.688 18.197C180.892 18.113 181.109 18.0699 181.329 18.0701C181.773 18.0707 182.198 18.2471 182.511 18.5606C182.824 18.8741 183 19.299 183 19.7421Z"
            fill={primaryColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M198.728 14.3778L192.482 20.624L193.896 22.0382L200.142 15.7921L206.388 22.0382L207.802 20.624L201.556 14.3778L208.038 7.89603L206.624 6.48182L200.142 12.9636L193.66 6.48182L192.246 7.89603L198.728 14.3778Z"
            fill={primaryColor}
        />
        <path
            d="M217.954 20.8952L226.475 6.89952C226.572 6.73992 226.7 6.60103 226.851 6.49077C227.002 6.38051 227.173 6.30105 227.354 6.25692C227.536 6.21279 227.724 6.20486 227.909 6.23358C228.094 6.2623 228.271 6.32711 228.43 6.42431C228.753 6.62064 228.984 6.93685 229.073 7.30344C229.162 7.67003 229.102 8.05701 228.906 8.37931L220.384 22.3753C220.188 22.6975 219.871 22.9285 219.505 23.0176C219.138 23.1067 218.751 23.0466 218.429 22.8506C218.107 22.6542 217.876 22.3379 217.786 21.9713C217.697 21.6046 217.758 21.2175 217.954 20.8952Z"
            fill={primaryColor}
        />
        <path
            d="M236.956 22.8505C236.634 23.0462 236.248 23.106 235.881 23.0169C235.515 22.9279 235.199 22.6971 235.003 22.3753L226.479 8.37931C226.282 8.05701 226.222 7.67003 226.312 7.30344C226.401 6.93685 226.632 6.62064 226.954 6.42431C227.113 6.32711 227.291 6.2623 227.475 6.23358C227.66 6.20486 227.848 6.21279 228.03 6.25692C228.211 6.30105 228.383 6.38051 228.533 6.49077C228.684 6.60103 228.812 6.73992 228.909 6.89952L237.432 20.8952C237.628 21.2175 237.688 21.6046 237.599 21.9713C237.51 22.3379 237.279 22.6542 236.956 22.8505Z"
            fill={primaryColor}
        />
        <path
            d="M227.818 20.1128C228.879 20.1128 229.739 19.2529 229.739 18.1922C229.739 17.1314 228.879 16.2715 227.818 16.2715C226.757 16.2715 225.898 17.1314 225.898 18.1922C225.898 19.2529 226.757 20.1128 227.818 20.1128Z"
            fill={primaryColor}
        />
        <path
            d="M235.948 2.44213L236.853 0.718542C236.861 0.70294 236.864 0.685154 236.861 0.667762C236.858 0.650371 236.85 0.634278 236.838 0.621818C236.825 0.609357 236.809 0.601177 236.792 0.598464C236.775 0.59575 236.757 0.598645 236.741 0.606726L235.018 1.51187C235.006 1.51798 234.992 1.52117 234.979 1.52117C234.966 1.52117 234.952 1.51798 234.94 1.51187L233.245 0.621588C233.229 0.613457 233.211 0.610506 233.194 0.613165C233.177 0.615823 233.161 0.623952 233.148 0.636373C233.136 0.648794 233.127 0.66486 233.125 0.682244C233.122 0.699627 233.125 0.717422 233.133 0.73305L234.03 2.44213C234.037 2.45396 234.04 2.46714 234.04 2.48052C234.04 2.4939 234.037 2.50708 234.03 2.51891L233.148 4.20003C233.14 4.21564 233.137 4.23342 233.139 4.25081C233.142 4.26821 233.15 4.2843 233.163 4.29676C233.175 4.30922 233.191 4.3174 233.209 4.32011C233.226 4.32283 233.244 4.31993 233.259 4.31185L234.94 3.42936C234.952 3.4231 234.966 3.41983 234.979 3.41983C234.992 3.41983 235.006 3.4231 235.018 3.42936L236.726 4.326C236.742 4.33414 236.76 4.33709 236.777 4.33443C236.794 4.33177 236.811 4.32364 236.823 4.31122C236.835 4.2988 236.844 4.28273 236.846 4.26535C236.849 4.24797 236.846 4.23017 236.838 4.21454L235.948 2.51891C235.942 2.50708 235.938 2.4939 235.938 2.48052C235.938 2.46714 235.942 2.45396 235.948 2.44213Z"
            fill={primaryColor}
        />
        <path
            d="M229.468 2.66293L230.282 2.12332C230.289 2.11846 230.295 2.11133 230.298 2.10299C230.301 2.09464 230.301 2.08553 230.299 2.077C230.296 2.06846 230.291 2.06096 230.284 2.0556C230.277 2.05024 230.268 2.04731 230.259 2.04724L229.284 2.03025C229.277 2.03045 229.27 2.02886 229.264 2.02564C229.258 2.02242 229.253 2.01768 229.249 2.01185L228.718 1.21216C228.713 1.20471 228.706 1.19902 228.698 1.19594C228.689 1.19286 228.68 1.19254 228.672 1.19505C228.663 1.19755 228.656 1.20273 228.65 1.20983C228.645 1.21692 228.642 1.22556 228.642 1.23445L228.625 2.20116C228.625 2.20814 228.624 2.21507 228.62 2.22126C228.617 2.22746 228.612 2.23272 228.607 2.23655L227.814 2.76024C227.807 2.76515 227.802 2.77227 227.799 2.78054C227.796 2.78882 227.795 2.79782 227.798 2.80626C227.8 2.8147 227.805 2.82213 227.812 2.82747C227.819 2.83281 227.828 2.83578 227.837 2.83596L228.788 2.85259C228.794 2.85279 228.801 2.8546 228.807 2.85787C228.813 2.86113 228.818 2.86575 228.821 2.87135L229.356 3.6767C229.361 3.68415 229.368 3.68984 229.377 3.69292C229.385 3.696 229.394 3.69632 229.403 3.69382C229.411 3.69132 229.419 3.68613 229.424 3.67904C229.429 3.67194 229.432 3.6633 229.432 3.65441L229.449 2.69513C229.45 2.6887 229.452 2.68243 229.455 2.67683C229.458 2.67124 229.462 2.66648 229.468 2.66293Z"
            fill={primaryColor}
        />
        <path
            d="M235.626 8.61249L236.527 8.01343C236.536 8.008 236.542 8.00013 236.545 7.99095C236.548 7.98177 236.549 7.97177 236.546 7.96239C236.543 7.95301 236.538 7.94474 236.53 7.93878C236.522 7.93281 236.513 7.92946 236.503 7.92922L235.421 7.91046C235.413 7.91022 235.406 7.90823 235.399 7.90467C235.393 7.9011 235.387 7.89606 235.383 7.88994L234.794 7.00284C234.789 6.99471 234.781 6.98852 234.771 6.98518C234.762 6.98185 234.752 6.98154 234.743 6.98431C234.733 6.98707 234.725 6.99276 234.719 7.00055C234.713 7.00834 234.71 7.01782 234.71 7.02761L234.691 8.10048C234.691 8.10796 234.689 8.1153 234.685 8.12184C234.682 8.12838 234.676 8.13392 234.67 8.13799L233.791 8.72219C233.783 8.72771 233.777 8.73565 233.773 8.74486C233.77 8.75406 233.77 8.76407 233.773 8.77345C233.775 8.78284 233.781 8.79111 233.789 8.79709C233.796 8.80307 233.806 8.80646 233.816 8.80676L234.871 8.82516C234.879 8.82516 234.886 8.82704 234.892 8.83063C234.899 8.83422 234.905 8.83939 234.909 8.84568L235.503 9.73985C235.508 9.74799 235.516 9.75418 235.525 9.75751C235.534 9.76085 235.545 9.76115 235.554 9.75839C235.563 9.75563 235.572 9.74994 235.578 9.74215C235.583 9.73436 235.587 9.72488 235.587 9.71508L235.604 8.65C235.605 8.64242 235.607 8.63502 235.61 8.62847C235.614 8.62192 235.62 8.61643 235.626 8.61249Z"
            fill={primaryColor}
        />
    </svg>
);

AdaloLogoMobile.propTypes = {
    primaryColor: string,
};

export default AdaloLogoMobile;
