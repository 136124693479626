import React, { useContext } from 'react';
import classnames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import FiverrLogo from '@fiverr-private/icons/logos/fiverr_logomaker_logo';
import LogoMakerContext from '../../lib/logoMakerContext';
import { LOGO_BASE_CLASS, INTEGRATIONS } from '../../constants';
import HomeLink from '../LogoMakerLinks/HomeLink';
import { DARK_COLOR, DESKTOP_LOGO_MAP, LIGHT_COLOR, MOBILE_LOGO_MAP } from './constants';

import './style.scss';

const LogoMakerLogo = () => {
    const { integration = INTEGRATIONS.NONE, isTransparent } = useContext(LogoMakerContext);
    const { userId } = getContext();
    const logoColor = isTransparent ? LIGHT_COLOR : DARK_COLOR;
    const validIntegration = integration || INTEGRATIONS.NONE;
    const homeLinkClassName = classnames(LOGO_BASE_CLASS, 'logomaker-logo', `${validIntegration}-integration`);

    const DesktopLogo = DESKTOP_LOGO_MAP[integration] || FiverrLogo;
    const MobileLogo = MOBILE_LOGO_MAP[integration] || FiverrLogo;

    return (
        <HomeLink userId={userId} className={homeLinkClassName}>
            <DesktopLogo primaryColor={logoColor} />
            <MobileLogo primaryColor={logoColor} />
        </HomeLink>
    );
};

export default LogoMakerLogo;
