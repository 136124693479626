import React, { useState } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import getHeroOffset from '@fiverr-private/variations_display/src/utils/getHeroOffset';
import * as HeaderPropTypes from '../../PropTypes';
import { INTEGRATIONS } from '../../constants';
import LogoMakerContext from '../logoMakerContext';
import usePageScroll from '../usePageScroll';
import getLogoMakerIntegrationFromPath from '../getLogoMakerIntegrationFromPath';

/**
 * withLogoMakerContext HOC is responsible for passing the LogoMakerContext
 * to the child components
 *
 * @param Composite
 * @returns {function(*): *}
 */
const withLogoMakerContext = (Composite) => {
    const LogoMakerContextWrapper = (props) => {
        const {
            showBackButton,
            integration: integrationProp,
            isTransparent: isTransparentProp = false,
            isSticky: isStickyProp = false,
        } = props;
        const { url } = getContext();
        const pathname = new URL(url).pathname;
        const integration = integrationProp || getLogoMakerIntegrationFromPath(pathname) || INTEGRATIONS.NONE;
        const [logoIdeasMenuIsOpened, setLogoIdeasMenuIsOpened] = useState(false);
        const [pageIsScrolledDown, setPageIsScrolledDown] = useState(false);

        usePageScroll(() => {
            const heroOffset = getHeroOffset();
            setPageIsScrolledDown(window.scrollY > heroOffset && heroOffset > 0);
        });

        const isSticky = isStickyProp && pageIsScrolledDown;
        const isTransparent = isTransparentProp && !isSticky && !logoIdeasMenuIsOpened;

        const logoMakerContext = {
            integration,
            showBackButton,
            isTransparent,
            isSticky,
            logoIdeasMenuIsOpened,
            setLogoIdeasMenuIsOpened,
        };

        return (
            <LogoMakerContext.Provider value={logoMakerContext}>
                <Composite {...props} />
            </LogoMakerContext.Provider>
        );
    };

    LogoMakerContextWrapper.propTypes = HeaderPropTypes.logoMakerProps;

    return LogoMakerContextWrapper;
};

export default withLogoMakerContext;
