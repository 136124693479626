import { DPR_1, DPR_2 } from './constants';

// Cloudinary url path transformation reference https://cloudinary.com/documentation/transformation_reference

const CLOUDINARY_PATH = 'https://fiverr-res.cloudinary.com';
const ASSET_PATH = 'v1/attachments/generic_asset/asset'; /* Houston upload path */

export const imgSrc = ({ width = 'auto', dpr = DPR_1, img }) => {
    const imgTransform = `q_auto,f_auto,w_${width},${dpr}`;
    return `${CLOUDINARY_PATH}/${imgTransform}/${ASSET_PATH}/${img}`;
};

export const imgSrcSet = ({ width, img, retinaImg }) => {
    const imgNormal = imgSrc({ width, dpr: DPR_1, img });
    const imgRetina = imgSrc({ width, dpr: DPR_2, img: retinaImg ? retinaImg : img });
    return `${imgNormal} 1x, ${imgRetina} 2x`;
};
