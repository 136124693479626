import { useCallback, useEffect } from 'react';
import { throttle } from 'lodash';

export default (listener) => {
    const handleScroll = useCallback(throttle(listener, 1000 / 60), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
