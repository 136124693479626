import React from 'react';
import * as HeaderPropTypes from '../../PropTypes';

/**
 * withLandingPageProps HOC is responsible for returning preconfigured LogoMaker or LoggedOutLogoMaker
 * header for landing pages
 *
 * @param Composite
 */
const withLandingPageProps = (Composite) => {
    const LogoMakerLP = (props) => (
        <Composite {...props} isSticky isTransparent showSeoLinks showJoinLink showFiverrLink={!props.user} />
    );
    LogoMakerLP.propTypes = HeaderPropTypes.logoMakerProps;

    return LogoMakerLP;
};

export default withLandingPageProps;
