import React from 'react';
import { string, shape } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import Link from '../../Links/Link';
import { bigQueryClickEvent, mixpanelInteractionEvent } from '../utils';

const ExploreLink = ({ link }) => {
    const { userId } = getContext();

    return (
        <Link href={link.path} bigQueryData={bigQueryClickEvent(userId, link)} mpData={mixpanelInteractionEvent(link)}>
            <I18n k={link.copiesKey} />
        </Link>
    );
};

ExploreLink.propTypes = {
    link: shape({
        copiesKey: string,
        bigQuery: string,
        mixpanel: string,
        path: string,
    }),
};

export default ExploreLink;
