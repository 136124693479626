import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Icon } from '@fiverr-private/fit';
import { l_chevron_left } from '@fiverr-private/icons';

import './style.scss';

const BackButton = ({ onClick = () => window.history.back(), transparent }) => (
    <Button
        className={classnames('back-btn flex-center', { transparent })}
        color={Button.COLORS.WHITE}
        onClick={onClick}
        fill={transparent ? Button.FILLS.GHOST : Button.FILLS.FULL}
    >
        <Icon className="back-btn-icon">{l_chevron_left}</Icon>
    </Button>
);

BackButton.propTypes = {
    transparent: PropTypes.bool,
    onClick: PropTypes.func,
};

export default BackButton;
