import React from 'react';
import { string, arrayOf, shape, node } from 'prop-types';
import ExploreLink from '../../LogoMakerLinks/ExploreLink';

import classes from './style.module.scss';

const LogoMakerColumn = ({ links, header }) => (
    <div className={classes.columns}>
        <b className={classes.columnsTitle}>{header}</b>
        <ul className={classes.columnsBody}>
            {links.map((link, index) => (
                <li key={index}>
                    <ExploreLink link={link} />
                </li>
            ))}
        </ul>
    </div>
);

LogoMakerColumn.propTypes = {
    links: arrayOf(
        shape({
            copiesKey: string,
            bigQuery: string,
            mixpanel: string,
            path: string,
        })
    ),
    header: node,
};

export default LogoMakerColumn;
