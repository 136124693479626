export const LINKS = {
    HOME: {
        bigQuery: 'home',
        mixpanel: 'Home ',
    },
    GO_TO_FIVERR: {
        bigQuery: 'go_to_fiverr',
        mixpanel: 'Go To Fiverr',
    },
    HIRE_A_DESIGNER: {
        bigQuery: 'hire_a_designer',
        mixpanel: 'Hire a Designer',
    },
    MY_PROFILE: {
        bigQuery: 'my_profile',
        mixpanel: 'My Profile',
    },
    MANAGE_LOGOS: {
        bigQuery: 'manage_logos',
        mixpanel: 'Manage Logos',
    },
    NEW_LOGO: {
        bigQuery: 'new_logo',
        mixpanel: 'New Logo',
    },
    HELP: {
        bigQuery: 'help',
        mixpanel: 'Help',
    },
    SELLING_LOGOS: {
        bigQuery: 'selling_logos',
        mixpanel: 'Selling Logos',
    },
    SAVED_LOGOS: {
        bigQuery: 'saved_logos',
        mixpanel: 'Saved Logos',
    },
    MY_DESIGNS: {
        bigQuery: 'saved_logos.designs',
        mixpanel: 'My Designs',
    },
    MY_FAVOURITES: {
        bigQuery: 'saved_logos.favourites',
        mixpanel: 'My Favourites',
    },
    LOGO_IDEAS: {
        bigQuery: 'logo_ideas',
        mixpanel: 'Logo Ideas',
    },
    ABOUT: {
        bigQuery: 'about',
        mixpanel: 'About',
    },
    BRANDING_HUB: {
        bigQuery: 'branding_hub',
        mixpanel: 'Branding Hub',
    },
    HOW_IT_WORKS: {
        bigQuery: 'how_it_works',
        mixpanel: 'How It Works',
    },
    GUIDES: {
        bigQuery: 'guides',
        mixpanel: 'Guides',
    },
    TESTIMONIALS: {
        bigQuery: 'testimonials',
        mixpanel: 'Testimonials',
    },
    PRICING: {
        bigQuery: 'pricing',
        mixpanel: 'pricing',
    },
    TUTORIALS: {
        bigQuery: 'tutorials',
        mixpanel: 'Tutorials',
    },
};

export const IDEAS_LINK_TYPES = {
    HUB: 'hub',
    TAG: 'tag',
    INDUSTRY: 'industry',
};

export const MIXPANEL_IDEAS_CLICK_TYPE = {
    [IDEAS_LINK_TYPES.HUB]: 'Hub',
    [IDEAS_LINK_TYPES.TAG]: 'Tags',
    [IDEAS_LINK_TYPES.INDUSTRY]: 'Industries',
};
