/**
 * Cloudinary specific device pixel ratio with value 1.0: https://cloudinary.com/documentation/transformation_reference#dpr_pixel_ratio
 * @type {String}
 */
export const DPR_1 = 'dpr_1.0';

/**
 * Cloudinary specific device pixel ratio with value 2.0: https://cloudinary.com/documentation/transformation_reference#dpr_pixel_ratio
 * @type {String}
 */
export const DPR_2 = 'dpr_2.0';
