import React, { useState } from 'react';
import { translate } from '@fiverr-private/i18n-react';
import LogoMakerColumn from '../LogoMakerColumn';
import { LOGO_MAKER_TRANSLATION_PREFIX } from '../../../constants';
import { IMAGE_SRC_SET, IMAGE_SRC, createPopularLogos, createSolutions, createLearnDiscover } from './constants';

import classes from './style.module.scss';

const LogoMakerMenu = () => {
    const [popularLogos] = useState(createPopularLogos);
    const [solutions] = useState(createSolutions);
    const [learnDiscover] = useState(createLearnDiscover);

    return (
        <div className={classes.menu}>
            <div className={classes.menuWhiteBg}>
                <div className={classes.menuContainer}>
                    <LogoMakerColumn
                        links={popularLogos}
                        header={translate(`${LOGO_MAKER_TRANSLATION_PREFIX}.popular_logos`)}
                    />
                    <LogoMakerColumn
                        links={solutions}
                        header={translate(`${LOGO_MAKER_TRANSLATION_PREFIX}.solutions`)}
                    />
                    <LogoMakerColumn
                        links={learnDiscover}
                        header={translate(`${LOGO_MAKER_TRANSLATION_PREFIX}.learn_discover`)}
                    />
                </div>
                <picture className={classes.illustration}>
                    <source media="(min-width: 1160px)" srcSet={IMAGE_SRC_SET} />
                    <img alt={translate(`${LOGO_MAKER_TRANSLATION_PREFIX}_alt`)} src={IMAGE_SRC} />
                </picture>
            </div>
            <div className={`${classes.menuBottomShadow} box-shadow-z5`}></div>
        </div>
    );
};

export default LogoMakerMenu;
