import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import { Button } from '@fiverr-private/fit';
import { HEADER_NAV_SOURCE } from '../../../constants';
import './style.scss';

export const MakeYourLogoLink = () => {
    const path = pathfinder('logo_maker_brief_page_logo_maker_brief', {}, { query: { source: HEADER_NAV_SOURCE } });

    return (
        <Button
            href={path}
            rel="nofollow"
            color={Button.COLORS.BLACK}
            fill={Button.FILLS.FULL}
            size={Button.SIZES.SMALL}
            className="nav-link-make-my-logo"
        >
            <I18n k="header.your_new_logo" />
        </Button>
    );
};
