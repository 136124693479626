import React from 'react';
import { number, string, node } from 'prop-types';
import pathfinder from '@fiverr-private/pathfinder';
import Link from '../../Links/Link';
import { LINKS } from '../constants';
import { bigQueryClickEvent, mixpanelInteractionEvent } from '../utils';

const HomeLink = ({ userId, children, className }) => {
    const path = pathfinder('logo_maker_buyer_landing_page');

    return (
        <Link
            className={className}
            href={path}
            bigQueryData={bigQueryClickEvent(userId, LINKS.HOME)}
            mpData={mixpanelInteractionEvent(LINKS.HOME)}
        >
            {children}
        </Link>
    );
};

HomeLink.propTypes = {
    userId: number,
    className: string,
    children: node,
};

export default HomeLink;
