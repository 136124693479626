import React from 'react';
import pathfinder from '@fiverr-private/pathfinder';
import { translate } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import Link from '../../Links/Link';
import { LINKS } from '../constants';
import { bigQueryClickEvent, mixpanelInteractionEvent } from '../utils';
import { LOGO_MAKER_TRANSLATION_PREFIX } from '../../../constants';

const PricingLink = () => {
    const { userId } = getContext();

    return (
        <Link
            href={pathfinder('logo_maker_pricing_page')}
            className="fiverr-link nav-link themed-link"
            bigQueryData={bigQueryClickEvent(userId, LINKS.PRICING)}
            mpData={mixpanelInteractionEvent(LINKS.PRICING)}
        >
            {translate(`${LOGO_MAKER_TRANSLATION_PREFIX}.pricing`)}
        </Link>
    );
};

export default PricingLink;
