import React from 'react';
import { string } from 'prop-types';
import { DARK_COLOR } from '../../constants';

const W3schoolsLogoDesktop = ({ primaryColor = DARK_COLOR }) => (
    <svg width="453" height="32" viewBox="0 0 453 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M70.4508 9.10229H68.7596C67.0406 9.10229 65.8841 9.63358 65.0404 11.4795V9.10229H58.73C57.011 9.10229 55.8544 9.63358 55.0108 11.4795V9.10229H49.853V24.7453H55.0077V16.8294C55.0077 14.6394 55.9448 13.3255 57.6955 13.3255H59.8834V24.7453H65.0435V16.8294C65.0435 14.6394 65.9814 13.3255 67.7313 13.3255H70.4508V9.10229Z"
            fill={primaryColor}
        />
        <path
            d="M24.0359 24.7453H28.5678L34.2254 9.10229H29.0368L26.2864 18.206L23.4734 9.10229H18.3157L24.0359 24.7453Z"
            fill={primaryColor}
        />
        <path
            d="M2.90645 24.7454H8.03484V13.3255H12.9114V24.7454H18.0011V9.10236H8.03484V8.13183C8.03484 7.0685 8.7827 6.40807 9.97093 6.40807H12.9067V2.18799H9.12109C5.40806 2.18799 2.90645 4.47474 2.90645 7.8194V9.10236H0V13.3255H2.90645V24.7454Z"
            fill={primaryColor}
        />
        <path
            d="M48.7908 16.6105C48.7908 13.3254 46.8184 8.88257 40.8826 8.88257C35.2876 8.88257 33.0378 13.2627 33.0378 16.9252C33.0378 20.6481 35.6005 24.9973 41.1955 24.9973C44.5083 24.9973 47.2904 23.3385 48.4153 20.6171L43.8524 19.3342C43.5395 20.3395 42.5707 20.8986 41.1955 20.8986C39.3514 20.8986 38.2597 19.8933 37.9762 18.1456H48.7282C48.7591 17.7999 48.7908 17.3305 48.7908 16.6105ZM37.9746 15.1087C38.2837 13.2318 39.2556 12.5118 40.8811 12.5118C42.6318 12.5118 43.6006 13.7638 43.7257 15.1087H37.9746Z"
            fill={primaryColor}
        />
        <path d="M75.3267 2H78.3181V24.7027H75.3267V2Z" fill={primaryColor} />
        <path
            d="M79.7173 16.9253C79.7173 12.2644 83.0788 8.89966 87.7359 8.89966C92.393 8.89966 95.7228 12.2621 95.7228 16.9253C95.7228 21.5885 92.3922 24.9517 87.7351 24.9517C83.078 24.9517 79.7173 21.5846 79.7173 16.9253ZM92.6704 16.9253C92.6704 13.7461 90.6663 11.5236 87.7359 11.5236C84.7754 11.5236 82.7705 13.7461 82.7705 16.9253C82.7705 20.1045 84.7792 22.3278 87.7359 22.3278C90.6663 22.3255 92.6704 20.1029 92.6704 16.9253Z"
            fill={primaryColor}
        />
        <path
            d="M110.899 26.1227C110.899 28.9322 109.142 31.0001 106.088 31.0001H101.277C98.0697 31.0001 96.343 29.117 96.343 26.4003C96.343 25.1042 97.0522 23.962 97.977 23.2211C97.2044 22.6651 96.7741 21.8624 96.7741 20.813C96.7741 19.5168 97.5467 18.6523 98.6553 17.541C97.977 16.6455 97.607 15.5303 97.607 14.2682C97.607 11.0272 100.136 8.89743 103.59 8.89743C104.431 8.89759 105.266 9.04359 106.057 9.32895L108.185 6.89062L110.097 8.58809L108.216 10.7534C109.093 11.7147 109.577 12.9705 109.573 14.2721C109.573 17.5448 107.044 19.6437 103.59 19.6437C102.449 19.6437 101.431 19.4279 100.567 19.025C99.9494 19.704 99.6736 20.0435 99.6736 20.5995C99.6736 21.4943 100.446 21.8368 101.4 21.8368H105.718C108.71 21.8314 110.899 23.252 110.899 26.1227ZM107.97 26.3392C107.97 24.8567 107.042 24.2396 105.471 24.2396H101.863C101.336 24.247 100.81 24.2056 100.29 24.1159C99.4882 24.7345 99.2417 25.5079 99.2417 26.3083C99.2417 27.5456 100.136 28.4999 101.369 28.4999H105.965C107.445 28.4999 107.97 27.5734 107.97 26.3392ZM100.476 14.2682C100.476 16.1242 101.894 17.2316 103.59 17.2316C105.286 17.2316 106.705 16.1204 106.705 14.2682C106.705 12.3852 105.286 11.2739 103.59 11.2739C101.893 11.2739 100.476 12.3852 100.476 14.2682Z"
            fill={primaryColor}
        />
        <path
            d="M110.985 16.9253C110.985 12.2644 114.347 8.89966 119.004 8.89966C123.661 8.89966 126.991 12.2621 126.991 16.9253C126.991 21.5885 123.66 24.9517 119.004 24.9517C114.347 24.9517 110.985 21.5846 110.985 16.9253ZM123.938 16.9253C123.938 13.7461 121.929 11.5236 119.004 11.5236C116.042 11.5236 114.038 13.7461 114.038 16.9253C114.038 20.1045 116.042 22.3278 119.004 22.3278C121.933 22.3255 123.938 20.1029 123.938 16.9253Z"
            fill={primaryColor}
        />
        <path
            d="M132.083 9.14415H135.076V11.922C135.723 10.1626 137.388 8.89746 139.67 8.89746C142.2 8.89746 144.081 10.0389 144.697 12.0766C145.315 10.3173 147.35 8.89746 149.755 8.89746C153.117 8.89746 155.214 11.212 155.214 14.8243V24.7028H152.222V15.6548C152.222 13.1848 150.986 11.5183 148.923 11.5183C146.548 11.5183 145.129 13.1848 145.129 15.6548V24.7028H142.138V15.6548C142.138 13.1848 140.902 11.5183 138.868 11.5183C136.494 11.5183 135.076 13.1848 135.076 15.6548V24.7028H132.083V9.14415Z"
            fill={primaryColor}
        />
        <path
            d="M169.785 14.5459V24.7028H166.916V22.1717C166.176 23.8383 164.202 24.9495 161.859 24.9495C158.744 24.9495 156.585 22.9737 156.585 20.2887C156.585 17.1396 158.62 15.2875 162.63 15.2875H165.991C166.577 15.2875 166.918 14.9782 166.918 14.4229V14.3611C166.918 12.5051 165.254 11.3048 163.094 11.3048C160.935 11.3048 159.424 12.6628 159.301 14.2683H156.557C156.834 11.212 159.517 8.89746 163.063 8.89746C166.947 8.89746 169.785 11.151 169.785 14.5459ZM166.916 17.8805V17.4165H162.907C160.563 17.4165 159.546 18.435 159.546 20.1951C159.546 21.5871 160.782 22.5715 162.414 22.5715C165.255 22.5708 166.916 20.7511 166.916 17.8805Z"
            fill={primaryColor}
        />
        <path
            d="M176.38 17.2625L174.437 19.0535V24.6989H171.415V2H174.437V15.3176L181.284 9.14405H185.077L178.539 15.224L185.663 24.7012H181.962L176.38 17.2625Z"
            fill={primaryColor}
        />
        <path
            d="M207.21 11.737C204.372 11.737 203.447 14.2681 203.447 17.6639V24.7012H200.456V9.14404H203.447V12.1384C204.157 10.0697 205.391 9.14404 207.58 9.14404H209.461V11.737H207.21Z"
            fill={primaryColor}
        />
        <path
            d="M196.362 19.6398C195.93 21.3992 194.45 22.3875 192.168 22.3875C189.454 22.3875 187.542 20.3807 187.295 17.4482H199.171C199.202 17.1706 199.232 16.6749 199.232 16.2751C199.232 12.3241 196.673 8.89746 191.862 8.89746C187.081 8.89746 184.368 12.478 184.368 16.8311C184.368 21.1525 187.359 24.9511 192.171 24.9511C195.672 24.9511 198.319 23.191 199.167 20.4294L196.365 19.6414L196.362 19.6398ZM191.86 11.3048C194.419 11.3048 196.023 12.8794 196.208 15.2566H187.388C187.85 12.6628 189.423 11.3048 191.86 11.3048Z"
            fill={primaryColor}
        />
        <path
            d="M212 22.9959V23.0346C212 23.5482 211.795 24.0406 211.433 24.4037C211.07 24.7669 210.578 24.9712 210.065 24.9718C209.81 24.9721 209.558 24.9222 209.323 24.825C209.088 24.7278 208.874 24.5852 208.694 24.4054C208.514 24.2255 208.371 24.0118 208.273 23.7766C208.175 23.5414 208.125 23.2893 208.125 23.0346V22.9982C208.124 22.7431 208.174 22.4905 208.272 22.2548C208.369 22.019 208.512 21.8048 208.692 21.6245C208.873 21.4441 209.086 21.3011 209.322 21.2036C209.558 21.1061 209.81 21.0561 210.065 21.0564C210.578 21.057 211.071 21.2616 211.433 21.6253C211.796 21.989 212 22.4819 212 22.9959Z"
            fill={primaryColor}
        />
        <path d="M224 10L238 24" stroke={primaryColor} strokeWidth="2" />
        <path d="M238 10L224 24" stroke={primaryColor} strokeWidth="2" />
        <path
            d="M278.226 6.62946C278.226 6.62946 279.069 7.53348 280.248 7.53348C281.179 7.53348 281.855 6.97098 281.855 6.20089C281.855 5.2567 280.991 4.82143 279.946 4.82143H279.33L278.962 3.99107L280.596 2.0558C280.944 1.64063 281.239 1.37946 281.239 1.37946C281.239 1.37946 280.978 1.39286 280.449 1.39286H277.79V0H283.234V1.01786L281.051 3.54241C282.284 3.71652 283.489 4.59375 283.489 6.14063C283.489 7.66071 282.337 9.06696 280.348 9.06696C278.453 9.06696 277.402 7.875 277.402 7.875L278.226 6.62946Z"
            fill={primaryColor}
        />
        <path
            d="M267.605 8.51127L274.797 21.3148L278.346 14.9867L271.556 2.89966H263.654L260.379 8.73225L257.105 2.89966H250L260.339 21.3148L260.379 21.2479L260.42 21.3148L267.605 8.51127Z"
            fill={primaryColor}
        />
        <path
            d="M250.074 29.9933H251.125C251.125 30.3013 251.393 30.5959 251.868 30.5959C252.31 30.5959 252.605 30.375 252.605 30.0803C252.605 29.8259 252.411 29.712 252.076 29.6384L251.473 29.4843C250.455 29.2165 250.194 28.6741 250.194 28.0982C250.194 27.3951 250.891 26.7522 251.875 26.7522C252.679 26.7522 253.589 27.1607 253.576 28.1651H252.511C252.511 27.8571 252.23 27.6629 251.909 27.6629C251.56 27.6629 251.319 27.8638 251.319 28.1451C251.319 28.3794 251.54 28.5134 251.801 28.5736L252.525 28.7678C253.542 29.029 253.723 29.6651 253.723 30.0803C253.723 30.9977 252.806 31.5 251.888 31.5C250.998 31.5 250.094 30.9576 250.074 29.9933Z"
            fill={primaryColor}
        />
        <path
            d="M254.654 29.1227C254.654 27.6897 255.806 26.7522 257.112 26.7522C257.895 26.7522 258.531 27.1004 258.946 27.6227L258.156 28.2522C257.922 27.9709 257.54 27.7968 257.132 27.7968C256.348 27.7968 255.786 28.3593 255.786 29.1227C255.786 29.8794 256.348 30.4553 257.132 30.4553C257.54 30.4553 257.922 30.2812 258.156 30L258.946 30.6294C258.531 31.1518 257.895 31.5 257.112 31.5C255.806 31.5 254.654 30.5558 254.654 29.1227Z"
            fill={primaryColor}
        />
        <path
            d="M264.357 28.8482V31.3794H263.232V28.9285C263.232 28.2053 262.797 27.8169 262.295 27.8169C261.779 27.8169 261.143 28.1183 261.143 28.9955V31.3861H260.018V24.4084H261.15V27.5491C261.371 27 262.1 26.7455 262.549 26.7455C263.714 26.7522 264.357 27.5357 264.357 28.8482Z"
            fill={primaryColor}
        />
        <path
            d="M265.536 29.1227C265.536 27.6897 266.647 26.7522 267.933 26.7522C269.219 26.7522 270.344 27.6897 270.344 29.1227C270.344 30.5558 269.219 31.5 267.933 31.5C266.647 31.5 265.536 30.5558 265.536 29.1227ZM269.212 29.1227C269.212 28.3459 268.629 27.7968 267.933 27.7968C267.237 27.7968 266.667 28.3459 266.667 29.1227C266.667 29.9129 267.237 30.4553 267.933 30.4553C268.629 30.4553 269.212 29.9129 269.212 29.1227Z"
            fill={primaryColor}
        />
        <path
            d="M271.301 29.1227C271.301 27.6897 272.413 26.7522 273.699 26.7522C274.984 26.7522 276.109 27.6897 276.109 29.1227C276.109 30.5558 274.984 31.5 273.699 31.5C272.42 31.5 271.301 30.5558 271.301 29.1227ZM274.978 29.1227C274.978 28.3459 274.395 27.7968 273.699 27.7968C273.002 27.7968 272.433 28.3459 272.433 29.1227C272.433 29.9129 273.002 30.4553 273.699 30.4553C274.402 30.4553 274.978 29.9129 274.978 29.1227Z"
            fill={primaryColor}
        />
        <path d="M277.355 31.3861V24.4084H278.48V31.3861H277.355Z" fill={primaryColor} />
        <path
            d="M279.705 29.9933H280.757C280.757 30.3013 281.025 30.5959 281.5 30.5959C281.942 30.5959 282.237 30.375 282.237 30.0803C282.237 29.8259 282.042 29.712 281.708 29.6384L281.105 29.4843C280.087 29.2165 279.826 28.6741 279.826 28.0982C279.826 27.3951 280.522 26.7522 281.507 26.7522C282.31 26.7522 283.221 27.1607 283.208 28.1651H282.136C282.136 27.8571 281.855 27.6629 281.533 27.6629C281.185 27.6629 280.944 27.8638 280.944 28.1451C280.944 28.3794 281.165 28.5134 281.426 28.5736L282.15 28.7678C283.167 29.029 283.348 29.6651 283.348 30.0803C283.348 30.9977 282.431 31.5 281.513 31.5C280.623 31.5 279.725 30.9576 279.705 29.9933Z"
            fill={primaryColor}
        />
        <path
            d="M295.225 24.2583L291.336 12.6578L294.456 12.6358L296.675 19.9521L299.092 14.3495H300.849L303.31 19.9081L305.529 12.6578H308.627L304.694 24.2583H302.541L299.926 18.0186L297.378 24.2583H295.225Z"
            fill={primaryColor}
        />
        <path
            d="M314.983 24.478C313.812 24.478 312.786 24.2217 311.907 23.7091C311.029 23.1964 310.223 22.3249 309.491 21.0945L311.819 19.6005C312.317 20.3915 312.837 20.9554 313.379 21.2923C313.936 21.6292 314.522 21.7976 315.137 21.7976C315.811 21.7976 316.397 21.5632 316.895 21.0945C317.407 20.6258 317.664 20.018 317.664 19.271C317.664 18.5093 317.444 17.9308 317.005 17.5353C316.58 17.1252 315.987 16.9201 315.225 16.9201C314.551 16.9201 313.724 17.1325 312.742 17.5573L311.995 15.5799L315.62 11.6032L310.479 11.5593V8.81292H319.663L320.234 9.95539L316.148 14.3935C316.968 14.4667 317.715 14.723 318.389 15.1624C319.077 15.5872 319.619 16.1511 320.015 16.8542C320.41 17.5573 320.608 18.3409 320.608 19.2051C320.608 20.2157 320.359 21.1165 319.861 21.9075C319.363 22.6984 318.689 23.3282 317.839 23.7969C316.99 24.251 316.038 24.478 314.983 24.478Z"
            fill={primaryColor}
        />
        <path
            d="M326.901 24.478C325.832 24.478 324.88 24.3023 324.045 23.9507C323.21 23.5992 322.536 23.1012 322.023 22.4567L323.913 20.8529C324.352 21.3362 324.85 21.6877 325.407 21.9075C325.963 22.1125 326.527 22.215 327.099 22.215C327.553 22.215 327.919 22.1125 328.197 21.9075C328.475 21.7024 328.615 21.4168 328.615 21.0506C328.615 20.7284 328.483 20.472 328.219 20.2816C327.955 20.1059 327.326 19.8862 326.33 19.6225C324.85 19.2417 323.803 18.7217 323.188 18.0626C322.646 17.4767 322.375 16.7517 322.375 15.8875C322.375 15.1991 322.58 14.5985 322.99 14.0859C323.4 13.5586 323.942 13.1485 324.616 12.8555C325.29 12.5626 326.022 12.4161 326.813 12.4161C327.721 12.4161 328.578 12.5772 329.384 12.8995C330.189 13.2217 330.863 13.6611 331.405 14.2177L329.779 15.9973C329.369 15.6165 328.893 15.3016 328.351 15.0526C327.824 14.7889 327.333 14.6571 326.879 14.6571C325.824 14.6571 325.297 15.0233 325.297 15.7557C325.312 16.1072 325.473 16.3855 325.78 16.5905C326.073 16.7956 326.74 17.0373 327.78 17.3156C329.171 17.6818 330.153 18.1651 330.724 18.7656C331.222 19.2783 331.471 19.9594 331.471 20.8089C331.471 21.512 331.266 22.1418 330.856 22.6984C330.46 23.255 329.918 23.6944 329.23 24.0166C328.541 24.3242 327.765 24.478 326.901 24.478Z"
            fill={primaryColor}
        />
        <path
            d="M338.749 24.478C337.651 24.478 336.662 24.2144 335.783 23.6871C334.919 23.1598 334.231 22.4421 333.718 21.534C333.22 20.6258 332.971 19.5932 332.971 18.4361C332.971 17.2936 333.22 16.2683 333.718 15.3602C334.231 14.4521 334.919 13.7344 335.783 13.2071C336.662 12.6798 337.651 12.4161 338.749 12.4161C339.789 12.4161 340.734 12.6065 341.583 12.9873C342.448 13.3682 343.121 13.9028 343.605 14.5912L341.957 16.5686C341.649 16.1438 341.225 15.785 340.683 15.492C340.141 15.1844 339.591 15.0306 339.035 15.0306C338.42 15.0306 337.87 15.1844 337.387 15.492C336.918 15.785 336.545 16.1877 336.267 16.7004C336.003 17.213 335.871 17.7916 335.871 18.4361C335.871 19.0806 336.01 19.6591 336.289 20.1718C336.567 20.6844 336.948 21.0945 337.431 21.4021C337.914 21.6951 338.456 21.8415 339.057 21.8415C339.628 21.8415 340.155 21.717 340.639 21.468C341.122 21.2044 341.561 20.8382 341.957 20.3695L343.605 22.3469C343.107 22.9913 342.418 23.5113 341.54 23.9068C340.661 24.2876 339.731 24.478 338.749 24.478Z"
            fill={primaryColor}
        />
        <path
            d="M345.604 24.2583V8H348.57V14.1738C348.966 13.6611 349.486 13.2437 350.13 12.9214C350.79 12.5846 351.478 12.4161 352.196 12.4161C354.861 12.4161 356.194 13.998 356.194 17.1618V24.2583H353.206V17.3815C353.206 15.7264 352.511 14.9281 351.119 14.9867C350.401 14.9867 349.801 15.2064 349.317 15.6458C348.849 16.0852 348.614 16.6272 348.614 17.2716V24.2583H345.604Z"
            fill={primaryColor}
        />
        <path
            d="M364.569 24.478C363.382 24.478 362.328 24.2217 361.405 23.7091C360.482 23.1818 359.757 22.464 359.23 21.5559C358.703 20.6478 358.439 19.6079 358.439 18.4361C358.439 17.279 358.703 16.2463 359.23 15.3382C359.757 14.4301 360.482 13.7197 361.405 13.2071C362.328 12.6798 363.382 12.4161 364.569 12.4161C365.726 12.4161 366.766 12.6798 367.689 13.2071C368.611 13.7197 369.336 14.4301 369.864 15.3382C370.391 16.2463 370.655 17.279 370.655 18.4361C370.655 19.6079 370.391 20.6478 369.864 21.5559C369.336 22.464 368.611 23.1818 367.689 23.7091C366.766 24.2217 365.726 24.478 364.569 24.478ZM364.569 21.8635C365.155 21.8635 365.682 21.717 366.151 21.4241C366.619 21.1165 366.978 20.7064 367.227 20.1937C367.491 19.6811 367.623 19.0952 367.623 18.4361C367.623 17.777 367.491 17.1911 367.227 16.6784C366.978 16.1658 366.619 15.763 366.151 15.47C365.682 15.1771 365.155 15.0306 364.569 15.0306C363.968 15.0306 363.434 15.1771 362.965 15.47C362.496 15.763 362.13 16.1658 361.866 16.6784C361.603 17.1911 361.471 17.777 361.471 18.4361C361.471 19.0952 361.603 19.6811 361.866 20.1937C362.13 20.7064 362.496 21.1165 362.965 21.4241C363.434 21.717 363.968 21.8635 364.569 21.8635Z"
            fill={primaryColor}
        />
        <path
            d="M378.408 24.478C377.221 24.478 376.167 24.2217 375.244 23.7091C374.321 23.1818 373.596 22.464 373.069 21.5559C372.542 20.6478 372.278 19.6079 372.278 18.4361C372.278 17.279 372.542 16.2463 373.069 15.3382C373.596 14.4301 374.321 13.7197 375.244 13.2071C376.167 12.6798 377.221 12.4161 378.408 12.4161C379.565 12.4161 380.605 12.6798 381.528 13.2071C382.45 13.7197 383.175 14.4301 383.703 15.3382C384.23 16.2463 384.494 17.279 384.494 18.4361C384.494 19.6079 384.23 20.6478 383.703 21.5559C383.175 22.464 382.45 23.1818 381.528 23.7091C380.605 24.2217 379.565 24.478 378.408 24.478ZM378.408 21.8635C378.994 21.8635 379.521 21.717 379.99 21.4241C380.458 21.1165 380.817 20.7064 381.066 20.1937C381.33 19.6811 381.462 19.0952 381.462 18.4361C381.462 17.777 381.33 17.1911 381.066 16.6784C380.817 16.1658 380.458 15.763 379.99 15.47C379.521 15.1771 378.994 15.0306 378.408 15.0306C377.807 15.0306 377.273 15.1771 376.804 15.47C376.335 15.763 375.969 16.1658 375.705 16.6784C375.442 17.1911 375.31 17.777 375.31 18.4361C375.31 19.0952 375.442 19.6811 375.705 20.1937C375.969 20.7064 376.335 21.1165 376.804 21.4241C377.273 21.717 377.807 21.8635 378.408 21.8635Z"
            fill={primaryColor}
        />
        <path d="M386.908 24.2583V8H389.896V24.2583H386.908Z" fill={primaryColor} />
        <path
            d="M396.975 24.478C395.906 24.478 394.954 24.3023 394.119 23.9507C393.284 23.5992 392.611 23.1012 392.098 22.4567L393.987 20.8529C394.427 21.3362 394.925 21.6877 395.481 21.9075C396.038 22.1125 396.602 22.215 397.173 22.215C397.627 22.215 397.993 22.1125 398.272 21.9075C398.55 21.7024 398.689 21.4168 398.689 21.0506C398.689 20.7284 398.557 20.472 398.294 20.2816C398.03 20.1059 397.4 19.8862 396.404 19.6225C394.925 19.2417 393.877 18.7217 393.262 18.0626C392.72 17.4767 392.449 16.7517 392.449 15.8875C392.449 15.1991 392.654 14.5985 393.065 14.0859C393.475 13.5586 394.017 13.1485 394.69 12.8555C395.364 12.5626 396.097 12.4161 396.887 12.4161C397.796 12.4161 398.652 12.5772 399.458 12.8995C400.264 13.2217 400.937 13.6611 401.479 14.2177L399.854 15.9973C399.443 15.6165 398.967 15.3016 398.425 15.0526C397.898 14.7889 397.407 14.6571 396.953 14.6571C395.899 14.6571 395.371 15.0233 395.371 15.7557C395.386 16.1072 395.547 16.3855 395.855 16.5905C396.148 16.7956 396.814 17.0373 397.854 17.3156C399.246 17.6818 400.227 18.1651 400.798 18.7656C401.296 19.2783 401.545 19.9594 401.545 20.8089C401.545 21.512 401.34 22.1418 400.93 22.6984C400.535 23.255 399.993 23.6944 399.304 24.0166C398.616 24.3242 397.84 24.478 396.975 24.478Z"
            fill={primaryColor}
        />
        <path
            d="M404.913 24.5C404.371 24.5 403.946 24.3462 403.639 24.0386C403.331 23.7164 403.177 23.2623 403.177 22.6764C403.177 22.1345 403.338 21.6951 403.661 21.3582C403.983 21.0067 404.4 20.8309 404.913 20.8309C405.47 20.8309 405.894 20.992 406.187 21.3142C406.495 21.6365 406.649 22.0905 406.649 22.6764C406.649 23.2037 406.488 23.6431 406.165 23.9947C405.858 24.3316 405.44 24.5 404.913 24.5Z"
            fill={primaryColor}
        />
        <path
            d="M414.209 24.478C413.111 24.478 412.122 24.2144 411.243 23.6871C410.379 23.1598 409.691 22.4421 409.178 21.534C408.68 20.6258 408.431 19.5932 408.431 18.4361C408.431 17.2936 408.68 16.2683 409.178 15.3602C409.691 14.4521 410.379 13.7344 411.243 13.2071C412.122 12.6798 413.111 12.4161 414.209 12.4161C415.249 12.4161 416.194 12.6065 417.043 12.9873C417.908 13.3682 418.581 13.9028 419.065 14.5912L417.417 16.5686C417.109 16.1438 416.685 15.785 416.143 15.492C415.601 15.1844 415.051 15.0306 414.495 15.0306C413.88 15.0306 413.33 15.1844 412.847 15.492C412.378 15.785 412.005 16.1877 411.726 16.7004C411.463 17.213 411.331 17.7916 411.331 18.4361C411.331 19.0806 411.47 19.6591 411.748 20.1718C412.027 20.6844 412.408 21.0945 412.891 21.4021C413.374 21.6951 413.916 21.8415 414.517 21.8415C415.088 21.8415 415.615 21.717 416.099 21.468C416.582 21.2044 417.021 20.8382 417.417 20.3695L419.065 22.3469C418.567 22.9913 417.878 23.5113 416.999 23.9068C416.121 24.2876 415.191 24.478 414.209 24.478Z"
            fill={primaryColor}
        />
        <path
            d="M426.447 24.478C425.261 24.478 424.206 24.2217 423.283 23.7091C422.361 23.1818 421.636 22.464 421.108 21.5559C420.581 20.6478 420.317 19.6079 420.317 18.4361C420.317 17.279 420.581 16.2463 421.108 15.3382C421.636 14.4301 422.361 13.7197 423.283 13.2071C424.206 12.6798 425.261 12.4161 426.447 12.4161C427.604 12.4161 428.644 12.6798 429.567 13.2071C430.49 13.7197 431.215 14.4301 431.742 15.3382C432.269 16.2463 432.533 17.279 432.533 18.4361C432.533 19.6079 432.269 20.6478 431.742 21.5559C431.215 22.464 430.49 23.1818 429.567 23.7091C428.644 24.2217 427.604 24.478 426.447 24.478ZM426.447 21.8635C427.033 21.8635 427.56 21.717 428.029 21.4241C428.498 21.1165 428.857 20.7064 429.106 20.1937C429.369 19.6811 429.501 19.0952 429.501 18.4361C429.501 17.777 429.369 17.1911 429.106 16.6784C428.857 16.1658 428.498 15.763 428.029 15.47C427.56 15.1771 427.033 15.0306 426.447 15.0306C425.847 15.0306 425.312 15.1771 424.843 15.47C424.375 15.763 424.008 16.1658 423.745 16.6784C423.481 17.1911 423.349 17.777 423.349 18.4361C423.349 19.0952 423.481 19.6811 423.745 20.1937C424.008 20.7064 424.375 21.1165 424.843 21.4241C425.312 21.717 425.847 21.8635 426.447 21.8635Z"
            fill={primaryColor}
        />
        <path
            d="M434.881 24.2583V12.6578H437.781L437.825 14.3056C438.236 13.749 438.748 13.3023 439.363 12.9654C439.979 12.6138 440.623 12.4381 441.297 12.4381C442.132 12.4381 442.827 12.6212 443.384 12.9873C443.941 13.3389 444.395 13.9028 444.746 14.6791C445.171 13.9907 445.742 13.4487 446.46 13.0533C447.192 12.6431 447.947 12.4381 448.723 12.4381C451.374 12.4381 452.7 14.0346 452.7 17.2277V24.2583H449.69V17.4474C449.69 15.8362 449.03 15.0306 447.712 15.0306C447.009 15.0306 446.423 15.2503 445.955 15.6897C445.501 16.1292 445.273 16.6784 445.273 17.3375V24.2583H442.264V17.4035C442.264 16.6418 442.095 16.0559 441.758 15.6458C441.421 15.2357 440.938 15.0306 440.308 15.0306C439.62 15.0306 439.041 15.2503 438.572 15.6897C438.118 16.1292 437.891 16.6638 437.891 17.2936V24.2583H434.881Z"
            fill={primaryColor}
        />
    </svg>
);

W3schoolsLogoDesktop.propTypes = {
    primaryColor: string,
};

export default W3schoolsLogoDesktop;
