import React from 'react';
import { string } from 'prop-types';
import { DARK_COLOR } from '../../constants';

const AdaloLogoDesktop = ({ primaryColor = DARK_COLOR }) => (
    <svg width="295" height="26" viewBox="0 0 295 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M60.8137 6.7648H59.3539C57.87 6.7648 56.8717 7.2228 56.1434 8.81413V6.7648H50.6962C49.2124 6.7648 48.214 7.2228 47.4858 8.81413V6.7648H43.0335V20.2501H47.4831V13.4261C47.4831 11.5381 48.292 10.4055 49.8032 10.4055H51.6919V20.2501H56.1461V13.4261C56.1461 11.5381 56.9557 10.4055 58.4662 10.4055H60.8137V6.7648Z"
            fill={primaryColor}
        />
        <path
            d="M20.7478 20.2501H24.6599L29.5436 6.7648H25.0647L22.6905 14.6128L20.2623 6.7648H15.8101L20.7478 20.2501Z"
            fill={primaryColor}
        />
        <path
            d="M2.50887 20.2501H6.93572V10.4055H11.1452V20.2501H15.5387V6.7648H6.93572V5.92814C6.93572 5.01147 7.58128 4.44214 8.60697 4.44214H11.1412V0.804138H7.87338C4.66828 0.804138 2.50887 2.77547 2.50887 5.6588V6.7648H0V10.4055H2.50887V20.2501Z"
            fill={primaryColor}
        />
        <path
            d="M42.1166 13.2375C42.1166 10.4055 40.414 6.57547 35.2902 6.57547C30.4605 6.57547 28.5185 10.3515 28.5185 13.5088C28.5185 16.7181 30.7306 20.4675 35.5603 20.4675C38.42 20.4675 40.8215 19.0375 41.7925 16.6915L37.8538 15.5855C37.5837 16.4521 36.7474 16.9341 35.5603 16.9341C33.9684 16.9341 33.0261 16.0675 32.7813 14.5608H42.0626C42.0892 14.2628 42.1166 13.8581 42.1166 13.2375ZM32.78 11.9428C33.0468 10.3248 33.8857 9.70414 35.2889 9.70414C36.8001 9.70414 37.6364 10.7835 37.7444 11.9428H32.78Z"
            fill={primaryColor}
        />
        <path d="M65.0224 0.642136H67.6048V20.2135H65.0224V0.642136Z" fill={primaryColor} />
        <path
            d="M68.8125 13.5088C68.8125 9.4908 71.7141 6.59013 75.7341 6.59013C79.7542 6.59013 82.6285 9.4888 82.6285 13.5088C82.6285 17.5288 79.7535 20.4281 75.7335 20.4281C71.7134 20.4281 68.8125 17.5255 68.8125 13.5088ZM79.9936 13.5088C79.9936 10.7681 78.2637 8.85214 75.7341 8.85214C73.1786 8.85214 71.448 10.7681 71.448 13.5088C71.448 16.2495 73.1819 18.1661 75.7341 18.1661C78.2637 18.1641 79.9936 16.2481 79.9936 13.5088Z"
            fill={primaryColor}
        />
        <path
            d="M95.729 21.4374C95.729 23.8594 94.2119 25.6421 91.5763 25.6421H87.4229C84.6546 25.6421 83.1641 24.0188 83.1641 21.6768C83.1641 20.5594 83.7763 19.5748 84.5746 18.9361C83.9077 18.4568 83.5362 17.7648 83.5362 16.8601C83.5362 15.7428 84.2031 14.9974 85.1601 14.0395C84.5746 13.2675 84.2551 12.3061 84.2551 11.2181C84.2551 8.42413 86.4385 6.58813 89.4195 6.58813C90.1457 6.58827 90.8664 6.71413 91.5496 6.96013L93.3862 4.85813L95.0368 6.32146L93.4129 8.18813C94.1697 9.01682 94.5876 10.0994 94.584 11.2215C94.584 14.0428 92.4012 15.8521 89.4195 15.8521C88.4345 15.8521 87.5562 15.6661 86.8107 15.3188C86.2771 15.9041 86.0391 16.1968 86.0391 16.6761C86.0391 17.4474 86.706 17.7428 87.5296 17.7428H91.2568C93.8391 17.7381 95.729 18.9628 95.729 21.4374ZM93.2002 21.6241C93.2002 20.3461 92.3999 19.8141 91.0434 19.8141H87.929C87.4741 19.8205 87.0196 19.7848 86.5712 19.7074C85.879 20.2408 85.6663 20.9074 85.6663 21.5974C85.6663 22.6641 86.4385 23.4868 87.5029 23.4868H91.4696C92.7473 23.4868 93.2002 22.6881 93.2002 21.6241ZM86.7313 11.2181C86.7313 12.8181 87.9557 13.7728 89.4195 13.7728C90.8834 13.7728 92.1085 12.8148 92.1085 11.2181C92.1085 9.59479 90.884 8.63679 89.4195 8.63679C87.955 8.63679 86.7313 9.59479 86.7313 11.2181Z"
            fill={primaryColor}
        />
        <path
            d="M95.8031 13.5088C95.8031 9.4908 98.7048 6.59013 102.725 6.59013C106.745 6.59013 109.619 9.4888 109.619 13.5088C109.619 17.5288 106.744 20.4281 102.725 20.4281C98.7055 20.4281 95.8031 17.5255 95.8031 13.5088ZM106.984 13.5088C106.984 10.7681 105.25 8.85214 102.725 8.85214C100.169 8.85214 98.4387 10.7681 98.4387 13.5088C98.4387 16.2495 100.169 18.1661 102.725 18.1661C105.254 18.1641 106.984 16.2481 106.984 13.5088Z"
            fill={primaryColor}
        />
        <path
            d="M114.016 6.8008H116.598V9.19547C117.157 7.6788 118.594 6.58813 120.564 6.58813C122.748 6.58813 124.372 7.57214 124.904 9.3288C125.437 7.81214 127.193 6.58813 129.27 6.58813C132.172 6.58813 133.982 8.58347 133.982 11.6975V20.2135H131.399V12.4135C131.399 10.2841 130.332 8.84747 128.551 8.84747C126.501 8.84747 125.277 10.2841 125.277 12.4135V20.2135H122.694V12.4135C122.694 10.2841 121.627 8.84747 119.872 8.84747C117.823 8.84747 116.598 10.2841 116.598 12.4135V20.2135H114.016V6.8008Z"
            fill={primaryColor}
        />
        <path
            d="M146.559 11.4575V20.2135H144.083V18.0315C143.444 19.4681 141.74 20.4261 139.718 20.4261C137.029 20.4261 135.165 18.7228 135.165 16.4081C135.165 13.6935 136.922 12.0968 140.383 12.0968H143.285C143.79 12.0968 144.085 11.8301 144.085 11.3515V11.2981C144.085 9.69814 142.648 8.66347 140.784 8.66347C138.92 8.66347 137.616 9.83414 137.51 11.2181H135.141C135.38 8.58347 137.696 6.58813 140.757 6.58813C144.11 6.58813 146.559 8.5308 146.559 11.4575ZM144.083 14.3321V13.9321H140.623C138.599 13.9321 137.721 14.8101 137.721 16.3275C137.721 17.5275 138.788 18.3761 140.196 18.3761C142.649 18.3755 144.083 16.8068 144.083 14.3321Z"
            fill={primaryColor}
        />
        <path
            d="M152.253 13.7995L150.575 15.3435V20.2101H147.966V0.642136H150.575V12.1228L156.485 6.8008H159.76L154.116 12.0421L160.265 20.2121H157.071L152.253 13.7995Z"
            fill={primaryColor}
        />
        <path
            d="M178.865 9.03613C176.416 9.03613 175.617 11.2181 175.617 14.1455V20.2121H173.035V6.8008H175.617V9.38213C176.23 7.5988 177.295 6.8008 179.185 6.8008H180.808V9.03613H178.865Z"
            fill={primaryColor}
        />
        <path
            d="M169.501 15.8488C169.128 17.3655 167.851 18.2175 165.881 18.2175C163.538 18.2175 161.887 16.4875 161.675 13.9595H171.926C171.953 13.7201 171.979 13.2928 171.979 12.9481C171.979 9.54213 169.769 6.58813 165.617 6.58813C161.49 6.58813 159.148 9.6748 159.148 13.4275C159.148 17.1528 161.73 20.4274 165.883 20.4274C168.906 20.4274 171.19 18.9101 171.923 16.5295L169.504 15.8501L169.501 15.8488ZM165.615 8.66346C167.824 8.66346 169.208 10.0208 169.368 12.0701H161.755C162.153 9.83413 163.511 8.66346 165.615 8.66346Z"
            fill={primaryColor}
        />
        <path
            d="M183 18.7421V18.7755C182.999 19.2182 182.823 19.6427 182.51 19.9557C182.197 20.2688 181.772 20.4449 181.329 20.4455C181.11 20.4457 180.892 20.4027 180.689 20.319C180.486 20.2352 180.302 20.1122 180.146 19.9572C179.991 19.8021 179.867 19.6179 179.783 19.4152C179.699 19.2124 179.655 18.995 179.655 18.7755V18.7441C179.655 18.5242 179.698 18.3064 179.782 18.1032C179.866 17.9 179.989 17.7153 180.145 17.5598C180.3 17.4043 180.485 17.2811 180.688 17.197C180.892 17.113 181.109 17.0699 181.329 17.0701C181.773 17.0707 182.198 17.2471 182.511 17.5606C182.824 17.8741 183 18.299 183 18.7421Z"
            fill={primaryColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M198.728 13.3778L192.482 19.624L193.896 21.0382L200.142 14.7921L206.388 21.0382L207.802 19.624L201.556 13.3778L208.038 6.89603L206.624 5.48182L200.142 11.9636L193.66 5.48182L192.246 6.89603L198.728 13.3778Z"
            fill={primaryColor}
        />
        <path
            d="M270.423 19.9607V4.3649C270.423 4.00577 270.566 3.66135 270.82 3.40741C271.074 3.15346 271.418 3.0108 271.777 3.0108C271.955 3.01029 272.132 3.04495 272.297 3.11277C272.461 3.18059 272.611 3.28025 272.737 3.40604C272.863 3.53183 272.963 3.68128 273.032 3.84582C273.1 4.01035 273.135 4.18675 273.135 4.3649V19.9607C273.135 20.3199 272.992 20.6643 272.738 20.9182C272.485 21.1722 272.14 21.3148 271.781 21.3148C271.603 21.3153 271.426 21.2807 271.262 21.2129C271.097 21.145 270.947 21.0454 270.821 20.9196C270.695 20.7938 270.595 20.6444 270.526 20.4798C270.458 20.3153 270.423 20.1389 270.423 19.9607Z"
            fill={primaryColor}
        />
        <path
            d="M217.48 19.1906L225.592 5.86532C225.684 5.71334 225.805 5.58105 225.949 5.47603C226.093 5.371 226.255 5.29528 226.428 5.25321C226.601 5.21113 226.78 5.20352 226.956 5.23081C227.132 5.2581 227.301 5.31975 227.453 5.41224C227.605 5.50477 227.737 5.6263 227.842 5.76991C227.946 5.91352 228.022 6.07638 228.064 6.2492C228.106 6.42202 228.113 6.60142 228.086 6.77714C228.059 6.95285 227.997 7.12146 227.904 7.27332L219.793 20.5948C219.7 20.7468 219.579 20.8791 219.435 20.9841C219.292 21.0891 219.129 21.1647 218.956 21.2067C218.783 21.2487 218.604 21.2561 218.428 21.2286C218.252 21.2012 218.083 21.1393 217.932 21.0466C217.626 20.8598 217.407 20.5596 217.323 20.2118C217.238 19.8641 217.295 19.4969 217.48 19.1906Z"
            fill={primaryColor}
        />
        <path
            d="M235.563 21.0466C235.411 21.1393 235.243 21.2012 235.067 21.2286C234.891 21.2561 234.712 21.2487 234.539 21.2067C234.366 21.1647 234.203 21.0891 234.059 20.9841C233.916 20.8791 233.794 20.7468 233.702 20.5948L225.59 7.27332C225.404 6.96653 225.346 6.59825 225.431 6.24933C225.516 5.9004 225.736 5.59934 226.042 5.41224C226.194 5.31975 226.363 5.2581 226.538 5.23081C226.714 5.20352 226.894 5.21113 227.066 5.25321C227.239 5.29528 227.402 5.371 227.546 5.47603C227.689 5.58105 227.811 5.71334 227.903 5.86532L236.015 19.1906C236.201 19.4968 236.257 19.8641 236.173 20.212C236.088 20.5599 235.869 20.86 235.563 21.0466Z"
            fill={primaryColor}
        />
        <path
            d="M282.182 21.6421C280.343 21.6421 278.78 21.0068 277.494 19.7361C276.208 18.4655 275.566 16.9021 275.569 15.0462C275.569 13.1911 276.21 11.6282 277.494 10.3576C278.777 9.08689 280.34 8.45156 282.182 8.45156C284.038 8.45156 285.606 9.08689 286.884 10.3576C288.162 11.6282 288.804 13.1911 288.809 15.0462C288.809 16.9021 288.167 18.4655 286.884 19.7361C285.6 21.0068 284.033 21.6421 282.182 21.6421ZM279.399 17.8802C280.145 18.6036 281.143 19.0082 282.182 19.0082C283.222 19.0082 284.22 18.6036 284.966 17.8802C285.721 17.128 286.098 16.1834 286.098 15.0462C286.098 13.909 285.722 12.9648 284.97 12.2135C284.224 11.4901 283.226 11.0855 282.186 11.0855C281.147 11.0855 280.149 11.4901 279.402 12.2135C278.647 12.9656 278.269 13.9099 278.269 15.0462C278.269 16.1825 278.646 17.1272 279.399 17.8802Z"
            fill={primaryColor}
        />
        <path
            d="M226.869 18.4411C227.878 18.4411 228.696 17.6228 228.696 16.6134C228.696 15.604 227.878 14.7857 226.869 14.7857C225.859 14.7857 225.041 15.604 225.041 16.6134C225.041 17.6228 225.859 18.4411 226.869 18.4411Z"
            fill={primaryColor}
        />
        <path
            d="M250.11 3.0108C249.751 3.0108 249.407 3.15347 249.153 3.40741C248.899 3.66135 248.756 4.00577 248.756 4.3649V10.5552C247.735 9.15365 246.273 8.45243 244.369 8.45157C242.648 8.45157 241.177 9.08691 239.957 10.3576C238.736 11.6282 238.126 13.1915 238.126 15.0475C238.126 16.8855 238.736 18.4441 239.957 19.7233C241.177 21.0025 242.648 21.6417 244.369 21.6408C246.274 21.6408 247.736 20.9315 248.756 19.5128V19.9607C248.756 20.3199 248.899 20.6643 249.153 20.9182C249.407 21.1722 249.751 21.3148 250.11 21.3148C250.469 21.3148 250.814 21.1722 251.068 20.9182C251.322 20.6643 251.464 20.3199 251.464 19.9607V4.3649C251.464 4.00577 251.322 3.66135 251.068 3.40741C250.814 3.15347 250.469 3.0108 250.11 3.0108ZM247.629 17.9187C246.877 18.6811 245.932 19.0623 244.795 19.0623C243.658 19.0623 242.714 18.6824 241.963 17.9225C241.209 17.161 240.833 16.2039 240.834 15.0513C240.836 13.8988 241.212 12.9417 241.963 12.1801C242.715 11.4195 243.659 11.0387 244.795 11.0378C245.932 11.037 246.876 11.4177 247.629 12.1801C248.363 12.9212 248.739 13.8491 248.756 14.9641V15.1373C248.737 16.2506 248.362 17.1777 247.629 17.9187Z"
            fill={primaryColor}
        />
        <path
            d="M265.909 8.612C265.55 8.612 265.205 8.75466 264.951 9.0086C264.697 9.26255 264.555 9.60697 264.555 9.96609V10.5565C263.535 9.15321 262.074 8.45156 260.172 8.45156C258.449 8.45156 256.978 9.08689 255.758 10.3576C254.538 11.6282 253.927 13.1915 253.927 15.0475C253.927 16.8855 254.538 18.4441 255.758 19.7233C256.978 21.0025 258.449 21.6417 260.172 21.6408C262.078 21.6408 263.541 20.9306 264.56 19.5102V20.0416C264.56 20.4007 264.703 20.7451 264.957 20.9991C265.211 21.253 265.555 21.3957 265.914 21.3957C266.273 21.3957 266.618 21.253 266.872 20.9991C267.125 20.7451 267.268 20.4007 267.268 20.0416V9.96609C267.268 9.78784 267.233 9.61134 267.165 9.44672C267.096 9.28209 266.996 9.13259 266.87 9.00678C266.743 8.88098 266.594 8.78134 266.429 8.7136C266.264 8.64585 266.087 8.61132 265.909 8.612ZM263.427 17.9187C262.674 18.6811 261.73 19.0623 260.593 19.0623C259.455 19.0623 258.511 18.6824 257.76 17.9225C257.008 17.161 256.631 16.2039 256.63 15.0513C256.63 13.8987 257.006 12.9417 257.76 12.1801C258.512 11.4194 259.456 11.0387 260.593 11.0378C261.729 11.037 262.674 11.4177 263.427 12.1801C264.174 12.9348 264.55 13.8846 264.555 15.0295V15.0719C264.551 16.2133 264.175 17.1623 263.427 17.9187Z"
            fill={primaryColor}
        />
        <path
            d="M291.557 3.11476L292.771 0.804457C292.782 0.783448 292.786 0.759594 292.782 0.736311C292.778 0.713028 292.767 0.691511 292.751 0.674842C292.734 0.658172 292.713 0.647206 292.689 0.643514C292.666 0.639822 292.642 0.643593 292.621 0.654287L290.311 1.86976C290.295 1.87798 290.277 1.88227 290.26 1.88227C290.242 1.88227 290.224 1.87798 290.208 1.86976L287.927 0.669689C287.906 0.658995 287.883 0.655224 287.859 0.658916C287.836 0.662608 287.814 0.673574 287.798 0.690244C287.781 0.706913 287.77 0.72843 287.766 0.751713C287.763 0.774996 287.767 0.79885 287.777 0.819859L288.982 3.11476C288.991 3.13063 288.995 3.14824 288.995 3.1661C288.995 3.18397 288.991 3.20158 288.982 3.21744L287.797 5.47513C287.786 5.49613 287.782 5.51999 287.786 5.54327C287.789 5.56655 287.8 5.58807 287.817 5.60474C287.834 5.62141 287.855 5.63238 287.879 5.63607C287.902 5.63976 287.926 5.63599 287.947 5.6253L290.206 4.44062C290.221 4.43173 290.239 4.42706 290.257 4.42706C290.275 4.42706 290.293 4.43173 290.308 4.44062L292.603 5.64455C292.624 5.65524 292.648 5.65901 292.671 5.65532C292.695 5.65163 292.716 5.64066 292.733 5.62399C292.75 5.60732 292.76 5.58581 292.764 5.56252C292.768 5.53924 292.764 5.51539 292.753 5.49438L291.558 3.21744C291.549 3.2019 291.544 3.18427 291.544 3.16627C291.544 3.14827 291.548 3.13052 291.557 3.11476Z"
            fill={primaryColor}
        />
        <path
            d="M283.628 3.42279L284.719 2.69761C284.729 2.69108 284.736 2.68148 284.74 2.67026C284.744 2.65903 284.745 2.64679 284.741 2.63536C284.738 2.62393 284.731 2.61394 284.721 2.60688C284.711 2.59982 284.7 2.59608 284.688 2.59621L283.379 2.57311C283.37 2.57283 283.361 2.57034 283.353 2.56587C283.345 2.56139 283.339 2.55507 283.334 2.54744L282.62 1.47828C282.614 1.46828 282.604 1.46067 282.593 1.45659C282.582 1.45251 282.569 1.45218 282.558 1.45565C282.546 1.45912 282.536 1.46621 282.529 1.47584C282.522 1.48548 282.519 1.49714 282.519 1.50909L282.496 2.80671C282.496 2.81565 282.494 2.82447 282.489 2.83233C282.485 2.84019 282.479 2.84683 282.471 2.85163L281.407 3.55884C281.398 3.56559 281.39 3.57518 281.386 3.58627C281.382 3.59737 281.382 3.60941 281.385 3.62072C281.389 3.63204 281.395 3.64205 281.405 3.64936C281.414 3.65668 281.425 3.66093 281.437 3.66152L282.72 3.68334C282.729 3.68339 282.738 3.68562 282.746 3.68987C282.754 3.69412 282.761 3.70024 282.766 3.70773L283.484 4.78972C283.491 4.79937 283.5 4.80666 283.511 4.81059C283.522 4.81452 283.535 4.81488 283.546 4.81163C283.557 4.80838 283.567 4.80167 283.574 4.79245C283.582 4.78323 283.586 4.77196 283.587 4.7602L283.608 3.4767C283.607 3.46682 283.608 3.45669 283.611 3.44726C283.614 3.43782 283.62 3.42941 283.628 3.42279Z"
            fill={primaryColor}
        />
        <path
            d="M294.223 10.4192L295.435 9.61442C295.446 9.60714 295.454 9.59653 295.459 9.58414C295.463 9.57176 295.463 9.55826 295.46 9.54562C295.456 9.53298 295.448 9.52188 295.438 9.51393C295.427 9.50599 295.414 9.50162 295.401 9.50147L293.948 9.4758C293.938 9.47576 293.928 9.47329 293.919 9.4686C293.91 9.46391 293.903 9.45713 293.897 9.44885L293.106 8.25776C293.099 8.24678 293.088 8.23842 293.076 8.23392C293.064 8.22941 293.05 8.22899 293.038 8.23273C293.025 8.23646 293.014 8.24415 293.006 8.25466C292.998 8.26517 292.994 8.27796 292.993 8.29113L292.968 9.73122C292.968 9.74131 292.965 9.75125 292.961 9.76018C292.956 9.76912 292.949 9.77679 292.941 9.78256L291.76 10.5668C291.749 10.574 291.741 10.5845 291.736 10.5968C291.732 10.609 291.731 10.6224 291.735 10.635C291.738 10.6476 291.746 10.6587 291.756 10.6667C291.766 10.6748 291.779 10.6793 291.792 10.6797L293.204 10.7041C293.214 10.7047 293.224 10.7076 293.232 10.7124C293.241 10.7173 293.248 10.7241 293.254 10.7324L294.051 11.9324C294.058 11.9434 294.069 11.9518 294.081 11.9563C294.094 11.9608 294.107 11.9612 294.12 11.9575C294.132 11.9537 294.144 11.946 294.152 11.9355C294.159 11.925 294.164 11.9122 294.164 11.8991L294.19 10.4692C294.19 10.4587 294.194 10.4486 294.2 10.4399C294.206 10.4311 294.214 10.424 294.223 10.4192Z"
            fill={primaryColor}
        />
    </svg>
);

AdaloLogoDesktop.propTypes = {
    primaryColor: string,
};

export default AdaloLogoDesktop;
