import React, { useState, useEffect, useRef } from 'react';
import { string } from 'prop-types';

const PortalTarget = ({ name, className }) => {
    const [ready, setReady] = useState(false);
    const ref = useRef();

    useEffect(() => {
        setReady(true);
        // eslint-disable-next-line promise/catch-or-return
        Promise.resolve().then(() => {
            const event = new CustomEvent(`${name}-target-ready`, {
                bubbles: true,
                detail: name,
            });
            ref.current.dispatchEvent(event);
        });
    }, [name]);

    return <div data-portal={name} data-portal-ready={ready ? '' : undefined} ref={ref} className={className} />;
};

PortalTarget.propTypes = {
    name: string,
    className: string,
};

export default PortalTarget;
