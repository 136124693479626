import React, { useState, useContext, useEffect } from 'react';
import { node } from 'prop-types';
import classnames from 'classnames';
import { Button } from '@fiverr-private/fit';
import { getContext } from '@fiverr-private/fiverr_context';
import LogoMakerContext from '../../lib/logoMakerContext';
import { PORTAL_NAME } from '../LogoMakerContentPortal/constants';
import Layout from '../Layout';
import BackButton from '../BackButton';
import LogoMakerLogo from '../LogoMakerLogo';
import Navicon from '../Navicon';
import MainRow from '../MainRow';
import PortalTarget from '../PortalTarget';

import './style.scss';

const LogoMakerLayout = ({ children }) => {
    const { showBackButton, isTransparent, isSticky } = useContext(LogoMakerContext);
    const [withBackButton, setWithBackButton] = useState(false);
    const history = typeof window !== 'undefined' ? window.history : undefined;
    useEffect(() => {
        const { referrer } = getContext();
        setWithBackButton(showBackButton && referrer && history?.length > 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBackButton, history]);

    const classes = classnames('logo-maker-header', {
        'theme-transparent': isTransparent,
        'with-back-button': withBackButton,
    });

    return (
        <Layout className={classes} isSticky={isSticky}>
            <MainRow>
                {withBackButton && <BackButton transparent={isTransparent} />}
                <LogoMakerLogo light={isTransparent} />
                <PortalTarget name={PORTAL_NAME} className="content-container" />
                {children}
                <Navicon fill={isTransparent ? Button.COLORS.WHITE : undefined} />
            </MainRow>
        </Layout>
    );
};

LogoMakerLayout.propTypes = {
    children: node,
};

export default LogoMakerLayout;
